//noScroll
class SelectToggle {
  constructor(el, opt) {
    this.el = el;
    if ($("." + this.el).length > 0) {
      this.event();
    }
  }
  event() {
    const selectWrap = '.js-contact-select';
    $(selectWrap).each(function(){
      $(this).find('select').on('change',function(){
        let thisVal = $(this).val();
        if(thisVal){
          $(this).parent(selectWrap).addClass('is-active');
        }else{
          $(this).parent(selectWrap).removeClass('is-active');
        }
      })
    })
  }
}
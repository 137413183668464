//noScroll
class Page {
  constructor(el, opt) {
    this.el = el;
    if ($("." + this.el).length > 0) {
      this.event();
    }
  }
  event() {
    const worksPageDisplay = () => {
      const numItems = document.querySelectorAll(".js-works-num")
      numItems.forEach((item, index) => {
        if (window.matchMedia('(min-width: 960px)').matches) {
          item.innerHTML = `00${index + 1}`
        }
        if (window.matchMedia('(max-width: 959px)').matches) {
        item.innerHTML = `00${index + 1}`
        }
      })
    }
    worksPageDisplay()
    
    new Swiper(".works-swiper", {
      slidesPerView: 1.5,
      spaceBetween: 30,
      loop: true, // ループ有効
      // speed: 2000, // ループの時間
      // autoplay: {
      //   delay: 4000, // 途切れなくループ
      // },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        type: "fraction",
      },
      breakpoints: {
        950: {
          spaceBetween: 50,
          slidesPerView: 3.5,
        },
      },
    });

  }
}
//noScroll
class NavToggle {
  constructor(el, opt) {
    this.el = el;
    if ($("." + this.el).length > 0) {
      this.event();
    }
  }
  event() {
    const toggleNav = '.js-nav-target';

    if ($(toggleNav).length) {
      $('.js-nav-toggle').each(function (i) {
        gsap.to(toggleNav, {
          scrollTrigger: {
            trigger: this, // この要素をトリガーとして使用
            start: 'top center',
            end: 'top center',
            onEnter: () => {
              $(toggleNav).find('li').removeClass('is-active');
              $(toggleNav).find(`li:eq(${i})`).addClass('is-active');
            },
            onEnterBack: () => {
              $(toggleNav).find('li').removeClass('is-active');
              $(toggleNav).find(`li:eq(${i})`).addClass('is-active');
            }
          },
        });
      });
    }

  }
}
//noScroll
class SmoothScroll {
  constructor(el,opt){
    this.el = el;
    this.event();
  }
  event(){
    // メディアクエリがtrueかどうかをチェック
    var headerHeight = $('.js-header-trigger').outerHeight();     //fixedのヘッダーの高さを取得
    // var urlHash = location.hash;    //URLハッシュを取得
    var animeSpeed = 500;   //スクロールのアニメーションスピード
    // if(urlHash) {
    //   $('body,html').stop().scrollTop(0);
    //   setTimeout(function(){
    //     var target = $(urlHash.split('&')[0]);
    //     var position = target.offset().top - headerHeight;
    //     $('body,html').stop().animate({scrollTop:position}, animeSpeed);
    //   }, 100);
    // }
    $('a[href^="#"]').on({
      'click': function(){
        if($(this).hasClass('js-float')) {
          headerHeight = headerHeight;
        }
        var href= $(this).attr("href");
        var target = $(href);
        var position = target.offset().top - headerHeight;
        $('body,html').stop().animate({scrollTop:position}, animeSpeed);
        headerHeight = $('.js-header-trigger').outerHeight();
      }
    });
  }
}
 
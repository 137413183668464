//noScroll
class Modal {
  constructor(el, opt) {
    this.el = el;
    if ($("." + this.el).length > 0) {
      this.event();
    }
  }
  event() {
    // $('.js-modal-trigger').click(function() {
    //   // ②乗せたボタンに連動したメガメニューをスライドで表示させる
    //   console.log("モーダル");
      
    //   $('.modal-on').toggleClass( 'is-active' );
    //   $('.cn-btn').toggleClass( 'is-active' );
    // });
  }
}
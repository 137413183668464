class MoreView {
  constructor(el, opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event() {
    let $contentItem = $('.js-content-item');
    let $worksMore = $('.js-works-more');

    let viewItem;

    // 幅が768px以上のビューポートをターゲットとするメディアクエリを作成
    const mediaQuery = window.matchMedia('(min-width: 768px)')
    // メディアクエリがtrueかどうかをチェック
    if (mediaQuery.matches) {
      viewItem = 15;
    } else {
      viewItem = 14;
    }
    

    if($contentItem.length > viewItem) {
      $contentItem.each(function(index, el) {
        if(index >= viewItem) {
          $(this).css({'display': 'none'});
        }
      })
    } else {
      $worksMore.css({'display': 'none'});
    }

    $worksMore.click(function() {
      $contentItem.css({'display': 'block'});
      $(this).css({'display': 'none'});
    })
  }
}

//noScroll
class PlanTab {
  constructor(el, opt) {
    this.el = el;
    if ($(this.el).length > 0) {
      this.event();
    }
  }
  event() {

    const targetContent = '.js-plan-content';
    $(this.el).on('click',function(){
      $('.js-plan-tab').removeClass('is-active');
      $(this).addClass('is-active');
      const thisIndex = $(this).index();
      $(targetContent).each(function(){
        if($(this).index() == thisIndex){
          $(this).addClass('is-active').fadeIn();
        }else{
          $(this).removeClass('is-active').fadeOut();
        }
      })
      wrapHeightChange();
    })

    function wrapHeightChange() {
      let totalHeight = 0;
      const $planContentWrap = $('.js-plan-content-wrap');

      $planContentWrap.children().each(function() {
        if ($(this).hasClass('is-active')) {
          totalHeight += $(this).outerHeight(true); // marginを含む高さを加算
        }
      });

      $planContentWrap.height(totalHeight);
    }

    wrapHeightChange();
      $(window).resize(wrapHeightChange);
    }
}
//noScroll
class OpenList {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
      $(document).on('click', '.open-btn', function() {
        const parent = $(this).parent('.voice-list');
        parent.toggleClass('open'); // クラスを追加・削除
        parent.find('.open').slideToggle(); // スライドトグル
    });
    // $('.voice-list').click(function() {
      
    //   $(this).find('.open').slideToggle();
    // })
  }
}

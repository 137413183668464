//noScroll
class CardEvent {
  constructor(el,opt){
    this.el = el;
    this.event();
  }
  event(){
    const windowWidth = $(window).width();
    const windowSm = 768;
    if (windowWidth <= windowSm) {
      $('.a-trigger').on('click', function() {
        var findElm = $(this).next(".js-card__back");//直後のアコーディオンを行うエリアを取得
        $(findElm).slideToggle();
        $(this).toggleClass("is-active"); 
          
        if ($(this).hasClass('is-open')) {
          $(this).removeClass('is-open');
        } else {
          $(this).addClass('is-close');
        }
      });
  
      $(window).on('load', function() {
        $('.c-acc').addClass("is-open"); 
        $(".is-open").each(function(index, element){ //is-openクラスを取得
          var Title =$(element).children('.a-trigger'); //openクラスの子要素のtitleクラスを取得
          $(Title).addClass('is-close');
          var Box =$(element).children('.a-area');
          $(Box).slideDown(500);
        });
      });
    } else {
      const cards = document.querySelectorAll('.js-card');

      function transition() {
        if (this.classList.contains('is-active')) {
          this.classList.remove('is-active')
        } else {
          this.classList.add('is-active');
        }
      }
      
      cards.forEach(card => card.addEventListener('click', transition));
    }
    
  }
}

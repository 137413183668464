// globals

// メガメニューの開閉
$(function () {
  const megaMenuParent = '.js-mega';
  const megaMenu = '.js-mega-list';
  if ($(window).width() > 960) {
    $(megaMenuParent).hover(function () {
      $(megaMenu).stop().fadeIn(500).css('display', 'flex');
    }, function () {
      $(megaMenu).stop().fadeOut(500);
    })
    $(megaMenu).hover(function () {
      $(this).stop().fadeIn(500).css('display', 'flex');
    }, function () {
      $(this).stop().fadeOut(500);
    })
  }
})

// セレクトボックスの変更時の自動遷移
$(function() {
  const selectAutChange = '[data-js=select-change]';
  $(selectAutChange).on('change',function(){
    let targetUrl = $(this).val();
    window.location.href = targetUrl;
  })
})

// ajaxでの記事取得
$(function() {
  // MoreボタンHTML
  let btn_html = '[data-js=ajax-add-post]';

  // data属性値を取得
  let load      = '[data-js=ajax-add-container]';   // 
  let cur_cnt   = $(load).data("cur-cnt");   // 表示済み件数
  let tgt_cnt   = $(load).data("tgt-cnt");   // Moreボタンで取得する件数
  let post_type = $(load).data("post-type"); // 投稿タイプ
  let taxonomy  = $(load).data("taxonomy");  // タクソノミー
  let term_slug = $(load).data("term-slug"); // タームのスラッグ 
  let tag  = $(load).data("tag");  // タクソノミー
  let tag_slug = $(load).data("tag-slug"); // タームのスラッグ 
  let all_cnt   = $(load).data("all-cnt");   // 投稿の全件数

  // 
  $(document).on("click", "[data-js=ajax-add-post]", function() {

    // Moreボタン削除
    $(btn_html).fadeOut();

    // WP側の関数を実行
    // ※ cf_ajax_get_more_post は functions.php 等に追加
    $.ajax({
        type: 'POST',
        url: ajaxurl,
        data: {
          'action': 'cf_ajax_get_more_post',
          'cur_cnt': cur_cnt,
          'tgt_cnt': tgt_cnt,
          'post_type': post_type,
          'taxonomy': taxonomy,
          'term_slug': term_slug,
          'tag': tag,
          'tag_slug': tag_slug,
        },
      })
      .done(function(data) {

        // 一覧の最後に取得した投稿データを追加する
        $(data).appendTo(load).hide().fadeIn();

        // 表示済み件数を増やす
        cur_cnt = cur_cnt + tgt_cnt;

        // Moreボタンを再度表示
        // ※ 表示済み件数より全件数が多い場合
        if (all_cnt > cur_cnt) {
          $(btn_html).fadeIn();
        }
      })
      .fail(function() {
        // エラー
        console.log('エラー');
      })
  });
});

// Video背景（TOP about）
$(function(){
  const videoBg = '.js-bg-toggle-target';
  const videoBgToggle = '.js-bg-toggle';
  const body = 'body';
  if($('.p-worksArchive').length == 0) {
    if($(videoBgToggle).length){
      gsap.to(videoBg, { // 動かしたい要素
        //opacity: "1",
        scrollTrigger: {
          trigger: videoBgToggle, // 要素がビューポートに入ったときにアニメーション開始
          start: 'top bottom', // アニメーション開始位置
          end: 'bottom top', // アニメーション終了位置
          //scrub: 0.1,
          // markers: true,
          toggleClass: {targets: videoBg, className: 'is-active'},
          toggleActions: 'play reverse play reverse'
        },
      });
      gsap.to(body, { // 動かしたい要素
        scrollTrigger: {
          trigger: videoBgToggle, // 要素がビューポートに入ったときにアニメーション開始
          start: 'top bottom', // アニメーション開始位置
          end: 'bottom top', // アニメーション終了位置
          //scrub: 0.1,
          // markers: true,
          toggleClass: {targets: body, className: 'is-video-on'},
          toggleActions: 'play reverse play reverse'
        },
      });
    }
  }
  
})
// Video背景（Footer）
$(function(){
  $('img').each(function(index, el) {
    $(this).attr('loading', '');
  })

  if($('.p-worksArchive').length == 0) {
    setTimeout(() => {
      const videoBg = '.js-bg-toggle-target';
      const videoBgFooterToggle = '.js-bg-footer-toggle';
      const body = 'body';
      gsap.to(videoBg, { // 動かしたい要素
        //opacity: "1",
        scrollTrigger: {
          trigger: videoBgFooterToggle, // 要素がビューポートに入ったときにアニメーション開始
          start: 'top center', // アニメーション開始位置
          end: 'bottom center', // アニメーション終了位置
          //scrub: 0.1,
          // markers: true,
          toggleActions: 'play reverse play reverse',
          toggleClass: {targets: videoBg, className: 'is-active'},
        },
      });
      gsap.to(body, { // 動かしたい要素
        //opacity: "1",
        scrollTrigger: {
          trigger: videoBgFooterToggle, // 要素がビューポートに入ったときにアニメーション開始
          start: 'top center', // アニメーション開始位置
          end: 'bottom center', // アニメーション終了位置
          //scrub: 0.1,
          // markers: true,
          toggleActions: 'play reverse play reverse',
          toggleClass: {targets: body, className: 'is-video-on'},
        },
      });
    }, 500);
  };
})
//noScroll
class ArchiveWorks {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

    let $contentItem = $('.js-content-item');

    if($contentItem.length > 15) {
      $contentItem.each(function(index, el) {
        if(index >= 15) {
          $(this).css({'display': 'none'});
        }
      })
    }
  }
}

//noScroll
class Contact {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    //全角から半角
    function replaceZtoH(str){
      str = str.replace(/[！-～]/g, function(s){
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
      });
      str = str.replace(/[-－﹣−‐⁃‑‒–—﹘―⎯⏤ーｰ─━]/g, '-');
      return str;
    }
    const el = document.querySelector('[name="電話番号"]');
    el.addEventListener('blur', () => {
      const br = el.value;
      const ar = replaceZtoH(br);
      console.log(ar);
      el.value = ar;
    });
  }
}

// globals

// メガメニューの開閉
$(function () {
  const megaMenuParent = '.js-mega';
  const megaMenu = '.js-mega-list';
  if ($(window).width() > 960) {
    $(megaMenuParent).hover(function () {
      $(megaMenu).stop().fadeIn(500).css('display', 'flex');
    }, function () {
      $(megaMenu).stop().fadeOut(500);
    })
    $(megaMenu).hover(function () {
      $(this).stop().fadeIn(500).css('display', 'flex');
    }, function () {
      $(this).stop().fadeOut(500);
    })
  }
})

// セレクトボックスの変更時の自動遷移
$(function() {
  const selectAutChange = '[data-js=select-change]';
  $(selectAutChange).on('change',function(){
    let targetUrl = $(this).val();
    window.location.href = targetUrl;
  })
})

// ajaxでの記事取得
$(function() {
  // MoreボタンHTML
  let btn_html = '[data-js=ajax-add-post]';

  // data属性値を取得
  let load      = '[data-js=ajax-add-container]';   // 
  let cur_cnt   = $(load).data("cur-cnt");   // 表示済み件数
  let tgt_cnt   = $(load).data("tgt-cnt");   // Moreボタンで取得する件数
  let post_type = $(load).data("post-type"); // 投稿タイプ
  let taxonomy  = $(load).data("taxonomy");  // タクソノミー
  let term_slug = $(load).data("term-slug"); // タームのスラッグ 
  let tag  = $(load).data("tag");  // タクソノミー
  let tag_slug = $(load).data("tag-slug"); // タームのスラッグ 
  let all_cnt   = $(load).data("all-cnt");   // 投稿の全件数

  // 
  $(document).on("click", "[data-js=ajax-add-post]", function() {

    // Moreボタン削除
    $(btn_html).fadeOut();

    // WP側の関数を実行
    // ※ cf_ajax_get_more_post は functions.php 等に追加
    $.ajax({
        type: 'POST',
        url: ajaxurl,
        data: {
          'action': 'cf_ajax_get_more_post',
          'cur_cnt': cur_cnt,
          'tgt_cnt': tgt_cnt,
          'post_type': post_type,
          'taxonomy': taxonomy,
          'term_slug': term_slug,
          'tag': tag,
          'tag_slug': tag_slug,
        },
      })
      .done(function(data) {

        // 一覧の最後に取得した投稿データを追加する
        $(data).appendTo(load).hide().fadeIn();

        // 表示済み件数を増やす
        cur_cnt = cur_cnt + tgt_cnt;

        // Moreボタンを再度表示
        // ※ 表示済み件数より全件数が多い場合
        if (all_cnt > cur_cnt) {
          $(btn_html).fadeIn();
        }
      })
      .fail(function() {
        // エラー
        console.log('エラー');
      })
  });
});

// Video背景（TOP about）
$(function(){
  const videoBg = '.js-bg-toggle-target';
  const videoBgToggle = '.js-bg-toggle';
  const body = 'body';
  if($('.p-worksArchive').length == 0) {
    if($(videoBgToggle).length){
      gsap.to(videoBg, { // 動かしたい要素
        //opacity: "1",
        scrollTrigger: {
          trigger: videoBgToggle, // 要素がビューポートに入ったときにアニメーション開始
          start: 'top bottom', // アニメーション開始位置
          end: 'bottom top', // アニメーション終了位置
          //scrub: 0.1,
          // markers: true,
          toggleClass: {targets: videoBg, className: 'is-active'},
          toggleActions: 'play reverse play reverse'
        },
      });
      gsap.to(body, { // 動かしたい要素
        scrollTrigger: {
          trigger: videoBgToggle, // 要素がビューポートに入ったときにアニメーション開始
          start: 'top bottom', // アニメーション開始位置
          end: 'bottom top', // アニメーション終了位置
          //scrub: 0.1,
          // markers: true,
          toggleClass: {targets: body, className: 'is-video-on'},
          toggleActions: 'play reverse play reverse'
        },
      });
    }
  }
  
})
// Video背景（Footer）
$(function(){
  $('img').each(function(index, el) {
    $(this).attr('loading', '');
  })

  if($('.p-worksArchive').length == 0) {
    setTimeout(() => {
      const videoBg = '.js-bg-toggle-target';
      const videoBgFooterToggle = '.js-bg-footer-toggle';
      const body = 'body';
      gsap.to(videoBg, { // 動かしたい要素
        //opacity: "1",
        scrollTrigger: {
          trigger: videoBgFooterToggle, // 要素がビューポートに入ったときにアニメーション開始
          start: 'top center', // アニメーション開始位置
          end: 'bottom center', // アニメーション終了位置
          //scrub: 0.1,
          // markers: true,
          toggleActions: 'play reverse play reverse',
          toggleClass: {targets: videoBg, className: 'is-active'},
        },
      });
      gsap.to(body, { // 動かしたい要素
        //opacity: "1",
        scrollTrigger: {
          trigger: videoBgFooterToggle, // 要素がビューポートに入ったときにアニメーション開始
          start: 'top center', // アニメーション開始位置
          end: 'bottom center', // アニメーション終了位置
          //scrub: 0.1,
          // markers: true,
          toggleActions: 'play reverse play reverse',
          toggleClass: {targets: body, className: 'is-video-on'},
        },
      });
    }, 500);
  };
})
//noScroll
class OpenList {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
      $(document).on('click', '.open-btn', function() {
        const parent = $(this).parent('.voice-list');
        parent.toggleClass('open'); // クラスを追加・削除
        parent.find('.open').slideToggle(); // スライドトグル
    });
    // $('.voice-list').click(function() {
      
    //   $(this).find('.open').slideToggle();
    // })
  }
}

class Mottoo {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $(window).on("load resize scroll", function () {
      var mvHeight = $(".js-mv").outerHeight(); // .js-mvの高さを取得
      if ($(window).scrollTop() > mvHeight) {
        $("html").addClass("is-scroll");
      } else {
        $("html").removeClass("is-scroll");
      }
    });

    $(window).on('scroll', function() {
      var footerTop = $('.js-footer').offset().top;
      var windowBottom = $(window).scrollTop() + $(window).height();
      var mvFixedHeight = $('.js-mv-fixed').outerHeight();
    
      if (windowBottom >= footerTop) {
        $('.js-mv-fixed').fadeOut();
      } else {
        $('.js-mv-fixed').fadeIn();
      }
    });

    let swiperInstance;

    function initializeSwiper() {
      if (window.innerWidth <= 959) {
        swiperInstance = new Swiper('.c-swiper-column', {
          slidesPerView: 2.2,
          spaceBetween: 12,
        });

        const swiperPriceElements = document.querySelectorAll('.c-swiper-price');
        swiperPriceElements.forEach((element) => {
          new Swiper(element, {
            slidesPerView: 1.4,
            spaceBetween: 12,
          });
        });
        // if (!swiperInstance) {

        // }
      } else {
        if (swiperInstance) {
          swiperInstance.destroy(true, true);
          swiperInstance = null;
        }
      }
    }

    $(document).ready(initializeSwiper);
    $(window).resize(initializeSwiper);

    $('.js-close').on('click', function() {
      $('.js-mv-fixed').css('display', 'none');
    });

    $('.js-q').click(function() {
      $(this).find('.plus').toggleClass('is-active');
      $(this).next().slideToggle(200);
    })

    // $('.js-acc').click(function() {
    //   $(this).find('.plus').toggleClass('is-active');
    //   $(this).next().slideToggle();
    // })

    $('.js-drawer').on('click', function() {
      $('html').toggleClass('is-open');
      $("html").toggleClass("is-scroll");
    });

    $('.js-nav-content a, .js-overlay').on('click', function() {
      $('html').removeClass('is-open');
    });

    $('.js-more-btn').click(function () {
      if (window.matchMedia('(max-width: 959px)').matches) {
        $('.js-faq-content').css('height', 'auto');
        $('.js-more-bg, .js-more-btn').css('display', 'none');
      }
    });


    $('.js-tab-area li').eq(1).addClass('is-active');
    $('.js-tab-content').eq(1).removeClass('no-active');
    $('.js-tab-area li').on('click', function() {
      const index = $(this).index();
      $('.js-tab-area li').removeClass('is-active');
      $(this).addClass('is-active');
      $('.js-tab-content').addClass('no-active');
      $('.js-tab-content').eq(index).removeClass('no-active');
    });


    var headerHeight = $('.js-head-mottoo').outerHeight(); // fixedのヘッダーの高さを取得
    var urlHash = location.hash; // URLハッシュを取得
    var animeSpeed = 500; // スクロールのアニメーションスピード
    
    if (urlHash) {
      $('body,html').scrollTop(0);
      setTimeout(function () {
        var target = $(urlHash);
        var position = target.offset().top - headerHeight;
        $('body,html').animate({ scrollTop: position }, animeSpeed);
      }, 500);
    }

    // アンカーリンクのクリックイベント処理
    $('a[href^="#"]').click(function () {
      const href = $(this).attr("href");
      const target = $(href);
      const position = target.offset().top - headerHeight;
      $('body,html').stop().animate({ scrollTop: position }, 500);
      return false;
    });

    gsap.to(
      ".js-icon-pop",
      {
        opacity: 1,
        y: 0,
        stagger: 0.3,
        duration: 0.2,
        scrollTrigger: {
          trigger: ".js-trigger-icon",
          start: "top bottom-=30%",
        }
      }
    );
    gsap.timeline()
      .to('.js-mv-copy', { clipPath: "inset(0 0% 0 0)", duration: 0.4 })
      .to('.js-mv-img', { y: '0%', opacity: 1, duration: 0.4 }, '<')
      .to(['.js-head-mottoo', '.js-drawer'], { y: '0%', opacity: 1, duration: 0.3 }, '-=0.1')
      .to('.js-marquee', { opacity: 1, duration: 0.4 },  '<')
      .to('.js-mv-fixed', { y: '0%', opacity: 1, duration: 0.4 },  '<');

    ScrollTrigger.matchMedia({
      "(max-width: 768px)": function() {
        ScrollTrigger.create({
          trigger: '.js-trigger-area01',
          start: 'top center',
          toggleClass: { targets: '.js-yellow-line', className: 'is-active' },
          once: true,
          duration: 0.2,
          // markers: true,
        });
        gsap.to(
          ".js-trigger-area li",
          {
            opacity: 1,
            y: 0,
            stagger: 0.3,
            duration: 0.3,
            scrollTrigger: {
              trigger: ".js-trigger-area",
              start: "top bottom-=15%",
              // markers: true,
            }
          }
        );
      },
      "(min-width: 769px)": function() {
        ScrollTrigger.create({
          trigger: '.js-trigger-area01',
          start: 'center-=20% center',
          toggleClass: { targets: '.js-yellow-line', className: 'is-active' },
          once: true,
        });
        gsap.to(
          ".js-trigger-area li",
          {
            opacity: 1,
            y: 0,
            stagger: 0.3,
            duration: 0.3,
            scrollTrigger: {
              trigger: ".js-trigger-area",
              start: "top bottom-=25%",
            }
          }
        );
      }
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.js-trigger-area02',
        start: 'top+=20% center',
        once: true,
        // markers: true,
      }
    });
    
    tl.to('.js-before-content', {
      y: '0%',
      opacity: 1,
      duration: 0.5,
      ease: 'power2.out'
    })
    .add(() => {
      if (window.innerWidth > 768) {
        gsap.to('.js-arrow-tb', {
          clipPath: 'inset(0 0 0 0)',
          duration: 0.4,
          ease: 'power2.out'
        });
        gsap.to('.js-logo-mottoo', {
          opacity: 1,
          duration: 0.4,
          ease: 'power2.out'
        });
      } else {
        gsap.to('.js-arrow-tb', {
          clipPath: 'inset(0 0 0 0)',
          duration: 0.4,
          ease: 'power2.out'
        });
        gsap.to('.js-logo-mottoo-sp', {
          opacity: 1,
          duration: 0.4,
          ease: 'power2.out'
        });
      }
    })
    .to('.js-after-content', {
      y: '0%',
      opacity: 1,
      duration: 0.5,
      ease: 'power2.out'
    },  '+=0.5');
    
    const tl02 = gsap.timeline({
      scrollTrigger: {
        trigger: '.js-trigger-area03',
        start: 'center-=20% center',
      },
    });

    tl02.to('.js-arrow-lr', {
      clipPath: 'inset(0 0 0 0)',
      duration: 0.3,
      ease: 'power2.inOut',
    });
    
    tl02.to(
      '.js-arrow-rl',
      {
        clipPath: 'inset(0 0 0 0)',
        duration: 0.3,
        ease: 'power2.inOut',
      },
      '+=0.1'
    );
  }
}

//noScroll
class Page {
  constructor(el, opt) {
    this.el = el;
    if ($("." + this.el).length > 0) {
      this.event();
    }
  }
  event() {
    const worksPageDisplay = () => {
      const numItems = document.querySelectorAll(".js-works-num")
      numItems.forEach((item, index) => {
        if (window.matchMedia('(min-width: 960px)').matches) {
          item.innerHTML = `00${index + 1}`
        }
        if (window.matchMedia('(max-width: 959px)').matches) {
        item.innerHTML = `00${index + 1}`
        }
      })
    }
    worksPageDisplay()
    
    new Swiper(".works-swiper", {
      slidesPerView: 1.5,
      spaceBetween: 30,
      loop: true, // ループ有効
      // speed: 2000, // ループの時間
      // autoplay: {
      //   delay: 4000, // 途切れなくループ
      // },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        type: "fraction",
      },
      breakpoints: {
        950: {
          spaceBetween: 50,
          slidesPerView: 3.5,
        },
      },
    });

  }
}
class RecruitMake {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $(window).on("load resize scroll", function () {
      var mvHeight = $(".js-mv").outerHeight(); // .js-mvの高さを取得
      if ($(window).scrollTop() > mvHeight) {
        $("html").addClass("is-scroll");
      } else {
        $("html").removeClass("is-scroll");
      }
    });

    new Swiper(".mock-swiper", {
      loop: true, // ループ有効
      effect: "creative",
      speed: 600,
      creativeEffect: {
        prev: {
          shadow: true,
          translate: ["-20%", 0, -1],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      },
    });

    let swiperInstance;

    function initializeSwiper() {
      if (window.innerWidth <= 959) {
        if (!swiperInstance) {
          swiperInstance = new Swiper('.c-swiper-column', {
            slidesPerView: 2.2,
            spaceBetween: 12,
          });
        }
      } else {
        if (swiperInstance) {
          swiperInstance.destroy(true, true);
          swiperInstance = null;
        }
      }
    }

    $(document).ready(initializeSwiper);
    $(window).resize(initializeSwiper);

    gsap.set(".js-mv-phone", { opacity: 0, y: 100 });
    gsap.set(".js-mv-copy", { clipPath: "inset(0 100% 0 0)" });
    gsap.set(".js-mv-award", { clipPath: "inset(0 100% 0 0)" });
    gsap.set(".js-mv-auto", { opacity: 0, y: 100 });
    gsap.set(".js-header-recruit", { opacity: 0, y: -50 });

    if (window.innerWidth <= 959) {
      gsap.set(".js-mv-fixed", { opacity: 0, y: 100 });
    } else {
      gsap.set(".js-mv-fixed", { opacity: 0, x: 100 });
    }

    // アニメーション
    gsap.timeline()
      .to(".js-mv-phone", {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "power3.out"
      })
      .to(".js-mv-copy", {
        clipPath: "inset(0 0% 0 0)",
        duration: 0.5,
        ease: "power3.out"
      }, "<")
      .to(".js-mv-award", {
        clipPath: "inset(0 0% 0 0)",
        duration: 0.5,
        ease: "power3.out"
      }, "<")
      .to(".js-mv-auto", {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "power3.out"
      })
      .to(".js-header-recruit", {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "power3.out"
      }, "<")
      .to(".js-mv-fixed", {
        opacity: 1,
        x: window.innerWidth > 959 ? 0 : undefined, // PCの場合はx方向にアニメーション
        y: window.innerWidth <= 959 ? 0 : undefined, // スマホの場合はy方向にアニメーション
        duration: 0.3,
        ease: "power3.out"
      }, "<");

    $('.js-close').on('click', function() {
      $('.js-mv-fixed').css('display', 'none');
    });

    $('.js-q').click(function() {
      $(this).find('.plus').toggleClass('is-active');
      $(this).next().slideToggle();
    })

    $('.js-acc').click(function() {
      $(this).find('.plus').toggleClass('is-active');
      $(this).next().slideToggle();
    })

    $('.js-drawer').on('click', function() {
      $('html').toggleClass('is-open');
      $("html").toggleClass("is-scroll");
    });

    $('.js-nav-content a').on('click', function() {
      $('html').removeClass('is-open');
    });

    $('.js-more-btn').click(function () {
      if (window.matchMedia('(max-width: 959px)').matches) {
        $('.js-faq-content').css('height', 'auto');
        $('.js-more-bg, .js-more-btn').css('display', 'none');
      }
    });

    gsap.set(".js-anime-bg", { clipPath: "inset(0 0 0 100%)" });

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.target.classList.contains('is-inview')) {
          gsap.to(".js-anime-bg", {
            clipPath: "inset(0 0 0 0%)",
            duration: 0.3,
            ease: "power2.inOut"
          });
        }
      });
    });
    
    observer.observe(document.querySelector(".js-contact-area"), { attributes: true, attributeFilter: ["class"] });
    
    

    var headerHeight = $('.js-header-recruit').outerHeight(); // fixedのヘッダーの高さを取得
    var urlHash = location.hash; // URLハッシュを取得
    var animeSpeed = 500; // スクロールのアニメーションスピード
    
    if (urlHash) {
      $('body,html').scrollTop(0);
      setTimeout(function () {
        var target = $(urlHash);
        var position = target.offset().top - headerHeight;
        $('body,html').animate({ scrollTop: position }, animeSpeed);
      }, 500);
    }

    // アンカーリンクのクリックイベント処理
    $('a[href^="#"]').click(function () {
      const href = $(this).attr("href");
      const target = $(href);
      const position = target.offset().top - headerHeight;
      $('body,html').stop().animate({ scrollTop: position }, 500);
      return false;
    });

    let scrollTop = 0;

    function scrollBan() {
      const body = $('body')[0];
      if (body.classList.contains('is-hidden')) {
        body.style.top = '';
        body.classList.remove('is-hidden');
        window.scrollTo(0, scrollTop);
      } else {
        scrollTop = window.scrollY;
        body.style.top = (scrollTop * -1) + 'px';
        body.classList.add('is-hidden');
      }
    }

    $('.js-modal-btn').on('click', function(e) {
      e.preventDefault();
      const modalId = $(this).data('modal');
      $(`#${modalId}`).addClass('is-active');
      $('.c-modal-overlay').addClass('is-active');
      $('.c-modal-close').addClass('is-active');
      scrollBan()
    });
  
    // モーダルを閉じる
    $('.js-modal-close, .c-modal-overlay').on('click', function() {
      $('.c-modal').removeClass('is-active');
      $('.c-modal-overlay').removeClass('is-active');
      $('.c-modal-close').removeClass('is-active');
      scrollBan()
    });
  }
}

//noScroll
class SColumn {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    new Swiper(".sClumn-Swiper", {
      slidesPerView: 1.7,
      spaceBetween: 20,
      // loop: true, // ループ有効
      speed: 500, // ループの時間
      autoplay: {
        delay: 4000,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        type: "fraction",
      },
      navigation: {
        nextEl: ".sClumn-Swiper-button-next",
        prevEl: ".sClumn-Swiper-button-prev",
      },
      breakpoints: {
        960: {
          centeredSlides: false,
          spaceBetween: 30,
          slidesPerView: 3,
        },
      },
    });
  }
}

//noScroll
class Top {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // mv spスクロール
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー






    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // mv スクロールイベント
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    // スクロールを通常に戻すためにコメントアウト　LCW小宮山
    // const mvImageChange = () => {
    //   const mvChangePoint01 = document.querySelector(".js-mvChangePoint01").offsetTop;
    //   const mvChangePoint02 = document.querySelector(".js-mvChangePoint02").offsetTop;
    //   const mvImages = document.querySelectorAll(".js-vision-img")

    //   window.addEventListener('scroll', (event) => {
    //     let scroll_y = window.scrollY;
    //     const mvImage01 = document.querySelector(".js-vision-img01")
    //     const mvImage02 = document.querySelector(".js-vision-img02")
    //     const mvImage03 = document.querySelector(".js-vision-img03")

    //     if( scroll_y < mvChangePoint01){

    //       mvImage01.style.opacity = 1
    //       mvImage02.style.opacity = 0
    //       mvImage03.style.opacity = 0

    //     }else if(mvChangePoint01 < scroll_y && scroll_y < mvChangePoint02){
    //       mvImage01.style.opacity = 0
    //       mvImage02.style.opacity = 1
    //       mvImage03.style.opacity = 0

    //     }else{
    //       mvImage01.style.opacity = 0
    //       mvImage02.style.opacity = 0
    //       mvImage03.style.opacity = 1

    //     }
		// 	});
    // }
    // $(function(){
    //   if (window.matchMedia('(min-width: 960px)').matches) {
    //     /* ウィンドウサイズ669以上、767以下の処理を記述 */
    //     mvImageChange();
    // }})

    // 通常のスライド実装
    new Swiper(".js-vision-swiper", {
      speed: 1000, // ループの時間
      loop: true, // ループ有効
      slidesPerView: 1,
      effect: 'fade',
      autoplay: {
        delay: 4000, // 途切れなくループ
      },
    });

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // ニュース部分 swiper
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    new Swiper(".headingNews-swiper", {
      speed: 1000, // ループの時間
      slidesPerView: 1,
      allowTouchMove: false,
      autoplay: {
        delay: 6000, // 途切れなくループ
      },

      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    });

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // 〆ニュース部分 swiper
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // Works部分 swiper
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー


    const worksPageDisplay = () => {

      const numItems = document.querySelectorAll(".js-works-num")
      numItems.forEach((item, index) => {
        if (window.matchMedia('(min-width: 960px)').matches) {
          item.innerHTML = `00${index + 1}`
        }
        if (window.matchMedia('(max-width: 959px)').matches) {
        item.innerHTML = `00${index + 2}`
        }
      })
    }


    worksPageDisplay()

    new Swiper(".works-swiper", {
      slidesPerView: 1.5,
      spaceBetween: 10,
      loop: true, // ループ有効
      // speed: 2000, // ループの時間
      // autoplay: {
        //   delay: 4000, // 途切れなくループ
        // },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "fraction",
        },
        breakpoints: {
          960: {
          spaceBetween: 80,
          slidesPerView: 3.5,
        },
      },
    });


    // new Swiper(".works-swiper", {
    //   slidesPerView: 4.1,
    //   spaceBetween: 80,
    //   loop: true, // ループ有効
    //   speed: 2000, // ループの時間
    //   // autoplay: {
    //   //   delay: 6000, // 途切れなくループ
    //   // },
    // });





    // let swiper, swiperbool;
    // const breakPoint = 959;

    // if (breakPoint > window.innerWidth) {
    //   swiperbool = false;
    // } else {
    //   createSwiper();
    //   swiperbool = true;
    // }

    // window.addEventListener('resize', function() {
    //   if (breakPoint > window.innerWidth && swiperbool) {
    //     swiper.destroy(false, true);
    //     swiperbool = false;
    //   } else if (breakPoint <= window.innerWidth && !(swiperbool)) {
    //     createSwiper();
    //     swiperbool = true;
    //   }
    // });

    // function createSwiper() {
    //   swiper = new Swiper(".works-swiper", {
    //     breakpoints: {
    //       960: {
    //         slidesPerView: 4.1,
    //         spaceBetween: 40,
    //       }
    //     },
    //   });
    // }

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // 〆Works部分 swiper
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // Works部分 動き
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    gsap.to('.s-works', { // 動かしたい要素
      opacity: "1",
      scrollTrigger: {
        trigger: '.s-mv', // 要素がビューポートに入ったときにアニメーション開始
        start: 'top+=200 top', // アニメーション開始位置
        end: 'bottom top', // アニメーション終了位置
        scrub: 1,
      }
    });

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // 〆Works部分 動き
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // What we do.部分 動き
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    gsap.to('.s-were__imgWrap', { // 動かしたい要素
      top: '60rem',
      scrollTrigger: {
        trigger: '.s-were__wrap', // 要素がビューポートに入ったときにアニメーション開始
        start: 'top bottom', // アニメーション開始位置
        end: 'bottom top', // アニメーション終了位置
        scrub: 10,
      }
    });

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // 〆What we do.部分 動き
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // コラム swiper
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    // option は使い回すので別に書く
    const options01 = {
      slidesPerView: 3,
      spaceBetween: 20,
      loop: true,

      navigation: {
        nextEl: '.js-column-swiper01-next',
        prevEl: '.js-column-swiper01-prev'
      }
    }
    const breakPoint = 850;
    let swiper01;
    if ( window.innerWidth > breakPoint ) {
      swiper01 = new Swiper('.js-column-swiper01', options01);
    } else {
      swiper01 = undefined;
    }
    window.addEventListener('resize', () => {
      if ( window.innerWidth > breakPoint ) {
        if( swiper01 ) return;
        swiper01 = new Swiper('.js-column-swiper01', options);
      } else {
        if( !swiper01 ) return;
        swiper01.destroy();
        swiper01 = undefined;
      }
    }, false);


    // option は使い回すので別に書く
    const options = {
      slidesPerView: 3,
      spaceBetween: 20,
      loop: true,

      navigation: {
        nextEl: '.js-column-swiper02-next',
        prevEl: '.js-column-swiper02-prev'
      }
    }
    let swiper02;
    if ( window.innerWidth > breakPoint ) {
      swiper02 = new Swiper('.js-column-swiper02', options);
    } else {
      swiper02 = undefined;
    }
    window.addEventListener('resize', () => {
      if ( window.innerWidth > breakPoint ) {
        if( swiper02 ) return;
        swiper02 = new Swiper('.js-column-swiper02', options);
      } else {
        if( !swiper02 ) return;
        swiper02.destroy();
        swiper02 = undefined;
      }
    }, false);

    $('.js-plan-content:not(.is-active)').css({'display': 'none'});

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // 〆コラム swiper
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // ローディング
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    if($(".js-loading").length) {
      var tl = gsap.timeline();

      tl.to(".s-mv__ttl--n", 1, {
        delay: 1,
        opacity: 1,
        scale: 1,
        ease: CustomEase.create("custom", "M0,0,C0,0,0.128,-0.087,0.178,0,0.222,0.078,0.332,0.535,0.364,0.596,0.4,0.664,0.499,0.793,0.542,0.826,0.6,0.87,0.696,0.936,0.798,0.968,0.912,1.004,1,1,1,1"),

        onStart: function() {
          $('html').toggleClass('is-hidden');
        }
      });

      tl.to(".s-mv__ttl--n path:first-of-type", 1, {
        x: '-5vw',
        delay: 1,
        opacity: 0,
        ease: CustomEase.create("custom", "M0,0,C0,0,0.144,-0.051,0.194,0.036,0.238,0.114,0.332,0.535,0.364,0.596,0.4,0.664,0.499,0.793,0.542,0.826,0.6,0.87,0.696,0.936,0.798,0.968,0.912,1.004,1,1,1,1"),
        onStart: function() {
          $('html').toggleClass('is-hidden');
        }
      });

      tl.to(".s-mv__ttl--n path:last-of-type", 1, {
        x: '5vw',
        opacity: 0,
        ease: CustomEase.create("custom", "M0,0,C0,0,0.144,-0.051,0.194,0.036,0.238,0.114,0.332,0.535,0.364,0.596,0.4,0.664,0.499,0.793,0.542,0.826,0.6,0.87,0.696,0.936,0.798,0.968,0.912,1.004,1,1,1,1"),
        onStart: function() {
          $('html').toggleClass('is-hidden');
        }
      }, "<");

      tl.to(".s-mv__ttl svg", .5, {
        opacity: 1,
      }, "-=0.5");

      tl.to(".re", .8, {
        translateX: 0,
        translateY: 0,
        ease: CustomEase.create("custom", "M0,0,C0,0,0.144,-0.051,0.194,0.036,0.238,0.114,0.332,0.535,0.364,0.596,0.4,0.664,0.499,0.793,0.542,0.826,0.6,0.87,0.696,0.936,0.798,0.968,0.912,1.004,1,1,1,1"),
        delay: .3,
      });

      tl.to(".vision", .8, {
        translateX: 0,
        translateY: 0,
        ease: CustomEase.create("custom", "M0,0,C0,0,0.144,-0.051,0.194,0.036,0.238,0.114,0.332,0.535,0.364,0.596,0.4,0.664,0.499,0.793,0.542,0.826,0.6,0.87,0.696,0.936,0.798,0.968,0.912,1.004,1,1,1,1"),
      }, "<");

      tl.to(".s-mv__ttl svg > *:not(g)", .5, {
        y: 0,
        opacity: 1,
        stagger: 0.05,  //0.5秒遅れてそれぞれ再生
      }, "-=.3");

      tl.to(".s-mv__ttl p", .5, {
        y: 0,
        opacity: 1,
      }, "-=1");

      tl.to(".s-mv__el", 1.5, {
        opacity: 1,
        stagger: 0.08,  //0.5秒遅れてそれぞれ再生
      }, "-=1");

      tl.to(".s-mv-news,.s-mv__scroll", .5, {
        opacity: 1,
      }, "-=1");

      if(window.matchMedia('(min-width: 980px)').matches) {
        tl.to(".js-header", .5, {
          top: '6rem',
          onComplete: function() {
            $('html').toggleClass('is-hidden');
          }
        }, "<");
      } else {
        tl.to(".js-header", .5, {
          translateY: 0,
          onComplete: function() {
            $('html').toggleClass('is-hidden');
          }
        }, "<");
      }
    }

    cookie.set('loading', 'true', '', '', '1');

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // ローディング
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  }
}

//noScroll
class Works {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){


    // 制作実績詳細のスクロールアクション

    $(function () {
      // スクロールを開始したら
      $(window).on("scroll", function () {



        // ファーストビューの高さを取得
        let mvHeight = $(".p-s-works__mv").height();
        let footerHight = $(".l-footer-contact").offset().top - 1000;
        if (window.matchMedia('(min-width: 960px)').matches) {
          if ($(window).scrollTop() > 10) {
            // スクロールの位置がファーストビューより下の場合にclassを付与
            $(".js-works-fixed").addClass("js-styleChange");
          } else {
            // スクロールの位置がファーストビューより上の場合にclassを外す
            $(".js-works-fixed").removeClass("js-styleChange");
          }
        }
        if (window.matchMedia('(max-width: 959px)').matches) {
          if ($(window).scrollTop() > 50) {
            $(".p-s-works__siteLink").addClass("js-isShow");
          }else{
            $(".p-s-works__siteLink").removeClass("js-isShow")
          }
        }


          if($(window).scrollTop() > footerHight){
            $(".js-works-fixed").addClass('js-hide');
            $(".p-s-works__siteLink").removeClass("js-isShow")
          }else{
            $(".js-works-fixed").removeClass('js-hide');
            $(".p-s-works__siteLink").addClass("js-isShow");
          }







      });
    });

  }
}

class WorksArchive {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    let page = 2;  // 最初は2ページ目からスタート
    let loading = false; // ローディングフラグ
    let noMorePosts = false; // 投稿がなくなったフラグ
    
    // スクロールイベントを監視
    $(window).on('scroll', function () {
      if (loading || noMorePosts) return;
    
      // ページ下部に近づいたら次を読み込む
      if ($(window).scrollTop() + $(window).height() > $(document).height() - 400) {
        loading = true; // ローディング中フラグを立てる
    
        $.ajax({
          url: ajaxurl, // WordPress が提供する変数
          type: 'POST',
          data: {
            action: 'load_more_posts',
            page: page,  // ページ番号を送信
            post_type: 'works',  // 投稿タイプ
            works_cat: params.works_cat,  // 必要に応じてカテゴリー情報も送信
          },
          success: function (response) {
            if (response === 'no_more_posts') {
              noMorePosts = true;  // 投稿がなくなった
            } else {
              $('#content').append(response);  // 投稿を追加
              page++;  // ページ番号をインクリメント
            }
          },
          complete: function () {
            loading = false;  // ローディングフラグを解除
          },
        });
      }
    });
  }
}

//noScroll
class Accordion {
  constructor(el,opt){
    $(".js-trigger").on('click', function() {
      $(this).toggleClass('is-active').next().slideToggle();
    })

    $(".js-trigger-child").on('click', function() {
      $(this).toggleClass('is-active').find('.js-trigger-child__target').slideToggle();
    })

    if (window.matchMedia('(max-width: 959px)').matches) {
      $(".js-trigger_sp").on('click', function() {
        $(this).toggleClass('is-active').next().slideToggle();
      })
    }
  }
}

var CFL = {
  UA: {
    raw: function raw() {
      return navigator.userAgent;
    },
    is: function is(_is) {
      var ua = CFL.UA.raw();
      if (_is === 'issp') {
        return CFL.UA.isSp();
      }
    },
    isSp: function isSp() {
      var ua = CFL.UA.raw();
      return ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0;
    },
    isTablet: function() {
      var ua = CFL.UA.raw();
      if(ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
      } else if (ua.indexOf('iPad') > -1
         || (ua.indexOf('Macintosh') > -1 && CFL.UA.Device.isTouch() )
         || ua.indexOf('Android') > 0) {
        return true;
      }

      return false;
    },
    isEdge: function() {
      return this.raw().indexOf('Edge') != -1;
    },
    isIE: function() {
      var ua = CFL.UA.raw();
      return this.raw().indexOf('Trident') != -1 ;
    }
  },
  Device: {
    isTouch: function() {
      return 'ontouchend' in document;
    }
  }
};

var isMobile = function () {
  return (window.matchMedia('(max-width: '+ APP.bp.mobile +'px)').matches)
}

var isPortrait = function() {
  let defaultOrientation = null;
  if('orientation' in window) {
    var o1 = (window.innerWidth < window.innerHeight);
    var o2 = (window.orientation % 180 == 0);
    defaultOrientation = (o1 && o2) || !(o1 || o2);
  }
  if('orientation' in window) {
    // defaultOrientationがtrueの場合、window.orientationが0か180の時は縦
    // defaultOrientationがfalseの場合、window.orientationが-90か90の時は横
    var o = (window.orientation % 180 == 0);
    if((o && defaultOrientation) || !(o || defaultOrientation)) {
      return true;
    } else {
      return false;
    }
  }
}
//noScroll
'use strict'

// Basic Exponential Tween For Vector1
class Tween {
  constructor(pos, alphaSpeed) {
    this.position = pos
    this.velocity = pos
    this.alphaSpeed = alphaSpeed
  }

  lerp(value1, value2, alpha) {
    return value1 + (value2 - value1) * alpha
  }

  step(target) {
    const alpha = Math.exp(-this.alphaSpeed * (1.0 / 60.0))
    const newPosition = this.lerp(target, this.position, alpha)

    this.velocity = newPosition - this.position
    this.position = newPosition
  }

  reset() {
    this.position = 0
    this.velocity = 0
  }
}

// Basic Exponential Tween For Vector2
class Tween2 {
  constructor(pos, alphaSpeed) {
    this.position = { x: pos.x, y: pos.y }
    this.velocity = { x: 0, y: 0 }
    this.alphaSpeed = alphaSpeed
    this.direction = 0
  }

  lerp(value1, value2, alpha) {
    return value1 + (value2 - value1) * alpha
  }

  step(target) {
    const alpha = Math.exp(-this.alphaSpeed * (1.0 / 60.0))
    const newPositionX = this.lerp(target.x, this.position.x, alpha)
    const newPositionY = this.lerp(target.y, this.position.y, alpha)

    this.velocity.x = newPositionX - this.position.x
    this.velocity.y = newPositionY - this.position.y
    this.position.x = newPositionX
    this.position.y = newPositionY
  }

  calcDirection(target) {
    this.direction = Math.atan2(this.position.y - target.y, this.position.x - target.x)
  }

  reset() {
    this.position.x = 0
    this.position.y = 0
    this.velocity.x = 0
    this.velocity.y = 0
    this.direction = 0
  }

  get x() {
    return this.position.x
  }

  get y() {
    return this.position.y
  }
}

class BtnFollowing {
  constructor(el,opt){
    this.el = el;
    this.opt = opt;
    this.$body = document.querySelector("." + this.el)
    this.$area = document.querySelector("." + this.opt)

    this.x = 0
    this.y = 0
    this.k = 0.5
    this.isSticked = false
    this.tween = new Tween2({ x: 0, y: 0 }, 6)

    this.start()
  }

  start() {
    this.$body.addEventListener('mouseenter', () => {
      this.isSticked = true
      this.update()
    })

    this.$body.addEventListener('mouseleave', () => {
      this.isSticked = false
    })
  }

  kill() {}

  update() {
    let cx = 0
    let cy = 0
    let tx = 0
    let ty = 0

    if (this.isSticked) {
      const domRect = this.$area.getBoundingClientRect()
      cx = domRect.left + domRect.width / 2
      cy = domRect.top + domRect.height / 2
      tx = (Mouse.x - cx) * (1.0 - this.k)
      ty = (Mouse.y - cy) * (1.0 - this.k)
    }

    this.tween.step({ x: tx, y: ty })
    this.x = this.tween.position.x
    this.y = this.tween.position.y

    this.$body.style.transform = `translate3d(${this.x}px, ${this.y}px, 0)`
  }
}

//noScroll
class CardEvent {
  constructor(el,opt){
    this.el = el;
    this.event();
  }
  event(){
    const windowWidth = $(window).width();
    const windowSm = 768;
    if (windowWidth <= windowSm) {
      $('.a-trigger').on('click', function() {
        var findElm = $(this).next(".js-card__back");//直後のアコーディオンを行うエリアを取得
        $(findElm).slideToggle();
        $(this).toggleClass("is-active"); 
          
        if ($(this).hasClass('is-open')) {
          $(this).removeClass('is-open');
        } else {
          $(this).addClass('is-close');
        }
      });
  
      $(window).on('load', function() {
        $('.c-acc').addClass("is-open"); 
        $(".is-open").each(function(index, element){ //is-openクラスを取得
          var Title =$(element).children('.a-trigger'); //openクラスの子要素のtitleクラスを取得
          $(Title).addClass('is-close');
          var Box =$(element).children('.a-area');
          $(Box).slideDown(500);
        });
      });
    } else {
      const cards = document.querySelectorAll('.js-card');

      function transition() {
        if (this.classList.contains('is-active')) {
          this.classList.remove('is-active')
        } else {
          this.classList.add('is-active');
        }
      }
      
      cards.forEach(card => card.addEventListener('click', transition));
    }
    
  }
}

var cookie = {
	/**
	 * 取得
	 * @param {string} cookieName 取得するCookieの名前
	 */
	get : function(cookieName) {
		if (!navigator.cookieEnabled) return false;

		var getCookieAllData  = document.cookie,
		    getCookieAllDatas = getCookieAllData.split(';'),
		    getCookieDatas    = {},
		    cookieKeyValue    = [],
		    cookieKey         = '',
		    cookieValue       = '';

		var hashLength = function(arr) {
			var result = 0, key = false;

			if (typeof arr !== 'object') return false;

			for (key in arr) result++;

			return result;
		};

		for (var i = 0, len = getCookieAllDatas.length; i < len; i++) {
			if (getCookieAllDatas[i] && getCookieAllDatas[i] !== '') {
				cookieKeyValue = getCookieAllDatas[i].split('=');
				cookieKey      = cookieKeyValue[0].replace(/^\s+/, '');
				cookieValue    = '';

				if (cookieKeyValue[1]) {
					cookieValue = decodeURIComponent(cookieKeyValue[1]);
				}

				getCookieDatas[cookieKey] = cookieValue;
			}
		}

		if (hashLength(getCookieDatas) > 0) {
			if (cookieName && cookieName in getCookieDatas) {
				return getCookieDatas[cookieName];
			} else {
				return getCookieDatas;
			}
		} else {
			return '';
		}
	},

	/**
	 * 設定
	 * @param {string} cookieName 設定するCookieの名前
	 * @param {string} value 設定する値
	 * @param {string} domain 有効ドメイン
	 * @param {string} path 有効パス
	 * @param {string} expires 有効期限
	 * @param {boolean} secure セキュアとするか
	 * @return {boolean} 設定に成功した場合はtrue、失敗した場合はfalseを返す
	 */
	set : function(cookieName, value, domain, path, expires, secure) {
		if (!navigator.cookieEnabled || !cookieName || !value) return false;

		var date, dt, ts, ts_after;

		var setCookieData = cookieName + '=' + encodeURIComponent(value) + ';';

		if (domain) setCookieData += 'domain=' + domain + ';';

		if (path) setCookieData += 'path=' + path + ';';

		if (expires) {
      dt = new Date();
      ts = dt.getTime();

      // 1時間後
      ts_after = ts + (1000 * 60 * 60 * expires);

      date = new Date(ts_after);
			setCookieData += 'expires=' + date.toGMTString() + ';';
		}

		if (secure && secure === true && location.protocol.match(/https/)) {
			setCookieData += 'secure';
		}

		document.cookie = setCookieData;

		return true;
	},

	/**
	 * 削除
	 * @param {string} cookieName 削除するCookieの名前
	 * @return {boolean} 削除に成功した場合はtrue、失敗した場合はfalseを返す
	 */
	del : function(cookieName) {
		if (!navigator.cookieEnabled || !cookieName) return false;

		var getCookieDatas = this.get();

		if (getCookieDatas[cookieName]) {
			var setCookieData = cookieName + '=;',
			    date          = new Date(0);

			setCookieData += 'expires=' + date.toGMTString() + '';

			document.cookie = setCookieData;

			return true;
		}

		return false;
	}
};
//noScroll
class Cursor {
  constructor(){
    const mouseStalker = document.querySelector('.cursor')
    let msPos = {
      // マウスストーカーの位置
      s: {
        x: document.documentElement.clientWidth / 2,
        y: document.documentElement.clientHeight / 2
      },
      // マウスポインターの位置
      m: {
        x: document.documentElement.clientWidth / 2,
        y: document.documentElement.clientHeight / 2
      }
    };
    
    // マウスストーカーをactiveにする
    if (window.matchMedia( "(pointer: fine)" ).matches) {
      document.addEventListener ("mousemove", msActivate);
    }
    function msActivate() {
      document.removeEventListener ("mousemove", msActivate);
      // mouseの位置セット
      document.addEventListener('mousemove', function(e){
        msPos.m.x = e.clientX;
        msPos.m.y = e.clientY;
      });
      // アニメーション開始
      requestAnimationFrame(msPosUpdate);
    }
    
    // マウスストーカーを動かす関数
    function msPosUpdate() {
      msPos.s.x += (msPos.m.x - msPos.s.x) * 0.1;
      msPos.s.y += (msPos.m.y - msPos.s.y) * 0.1;
      const x = Math.round(msPos.s.x * 10) / 10;
      const y = Math.round(msPos.s.y * 10) / 10;
      mouseStalker.style.transform = `translate3d(` + x + 'px,' + y + 'px, 0)';
      requestAnimationFrame(msPosUpdate);
    }
    
    // hover時にclass追加
    const stalkerLinkObj = document.querySelectorAll('.cursor_hover');
    for (let i = 0; i < stalkerLinkObj.length; i++) {
      stalkerLinkObj[i].addEventListener('mouseover', function(){
        mouseStalker.classList.add('is-active');
        if(stalkerLinkObj[i].classList.contains('black')) {
          mouseStalker.classList.add('black');
        }
      });
      stalkerLinkObj[i].addEventListener('mouseout', function(){
        mouseStalker.classList.remove('is-active');
        mouseStalker.classList.remove('black');
      });
    }
  }
}

//noScroll
class Header {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $(".js-drawer").click(function() {
       $("." + this.el).toggleClass('is-active');
       $(".js-sp-menu").toggleClass('is-active');
       $('html').toggleClass('is-hidden is-drawer-active');
    })

    $(".js-aco").click(function() {
      $(this).toggleClass('is-active').next().slideToggle();
      $(this).find('.js-aco-trigger').toggleClass('is-active');
    })

    let options = {
      threshold: [0]
    }
    const observerEnter = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio >= 0.9) {
          $("." + this.el).removeClass('is-scroll');
        } else {
          $("." + this.el).addClass('is-scroll');
        }
      });
    }, options);
    let el = document.querySelector('.js-header-bgTrigger')
    observerEnter.observe(el);
  }
}

//noScroll
class ImgParallax {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    // this.para_ele = document.querySelectorAll("." + this.el);
    // this.para_ele.forEach((ele, index)=> {
    //   if(ele.classList.contains('js-parallax--reverse')) {
    //     this.translateY = '20px';
    //   } else {
    //     this.translateY = '-20px';
    //   }
    //   ele.classList.add(this.el + index);
    //   gsap.to('.' + this.el + index + ' img', {
    //     translateY: this.translateY,
    //     scrollTrigger: {
    //       trigger: '.' + this.el + index, // 要素がビューポートに入ったときにアニメーション開始
    //       start: 'top bottom',
    //       scrub: 3,
    //     }
    //   })
    // })


    $(function(){
      if (window.matchMedia('(min-width:960px)').matches) {
        /* ウィンドウサイズ669以上、767以下の処理を記述 */
        gsap.utils.toArray('.js-parallax-fideDown').forEach(wrap => {
          const y = wrap.getAttribute('data-y') || +100;

          gsap.to(wrap, {
            y: y,
            scrollTrigger: {
              trigger: wrap,
              start: 'top bottom',
              end: 'bottom top',
              scrub: 1,

            }
          })
        });
    }})

  }
}

class InviewEvent {
  constructor(el, opt){
    this.el = el;
    this.all = this.el + '-all';
    this.eachUp = this.el + '-each';
    this.eachItem = this.el + '-eachItem';
    this.eachItemFast = this.el + '-eachItem-fast';
    this.eachItemQuick = this.el + '-eachItem-quick';
    this.eachItemDelay = this.el + '-eachItem-delay';
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    $('.js-inview-load').addClass('is-inview');
    
    let options = {
      threshold: [0, 0.05, 0.1, 1]
    }
    let optionsAll = {
      threshold: [0, 0.9, 1]
    }
    const observerEnter = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const item = entry.target;
        if(entry.intersectionRatio >= 0.05){
          item.classList.add('is-inview');
          if(item.classList.contains('js-inview-each')){
            this.inviewEachUp(item);
          }
          if(item.classList.contains('js-inview-lottie')){
            this.inviewLottie(item);
          }
          observerEnter.unobserve(item);
        }
      });
    }, options);
    const observerAll = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const item = entry.target;
        if(entry.intersectionRatio >= 0.9){
          item.classList.add('is-inview');
          if(item.classList.contains('js-inview-each')){
            this.inviewEachUp(item);
          }
          observerAll.unobserve(item);
        } else {
          item.classList.remove('is-inview');
        }
      });
    }, optionsAll);
    const inviewItem = document.querySelectorAll(this.el);
    const inviewAllItem = document.querySelectorAll(this.all);
    inviewItem.forEach((e)=>{
      observerEnter.observe(e);
    });
    inviewAllItem.forEach((e)=>{
      observerAll.observe(e);
    });
  }
  inviewEachUp(item){
    $(item).find(this.eachItem).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.2 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
    $(item).find(this.eachItemFast).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.1 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
    $(item).find(this.eachItemQuick).each((i, el) => {
      gsap.to($(el), {
        // duration: 0.4,
        // alpha: 1,
        delay: (0.03 * i),
        // y: 0,
        // x: 0,
        onComplete: () => {
          $(el).addClass('is-inview');
        }
      });
    });    
    $(item).find(this.eachItemDelay).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.5 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
  }
  inviewLottie(item){
    if($(item).hasClass('js-lottie-flow')) {
      lottie.flow();
    }
    if($(item).hasClass('js-lottie-no01')) {
      lottie.no01();
    }
    if($(item).hasClass('js-lottie-no02')) {
      lottie.no02();
    }
    if($(item).hasClass('js-lottie-no03')) {
      lottie.no03();
    }
    if($(item).hasClass('js-lottie-no04')) {
      lottie.no04();
    }
    if($(item).hasClass('js-lottie-no05')) {
      lottie.no05();
    }
    if($(item).hasClass('js-lottie-no06')) {
      lottie.no06();
    }
    if($(item).hasClass('js-lottie-no07')) {
      lottie.no07();
    }
  }
}

//noScroll
class Modal {
  constructor(el, opt) {
    this.el = el;
    if ($("." + this.el).length > 0) {
      this.event();
    }
  }
  event() {
    // $('.js-modal-trigger').click(function() {
    //   // ②乗せたボタンに連動したメガメニューをスライドで表示させる
    //   console.log("モーダル");
      
    //   $('.modal-on').toggleClass( 'is-active' );
    //   $('.cn-btn').toggleClass( 'is-active' );
    // });
  }
}
class MoreView {
  constructor(el, opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event() {
    let $contentItem = $('.js-content-item');
    let $worksMore = $('.js-works-more');

    let viewItem;

    // 幅が768px以上のビューポートをターゲットとするメディアクエリを作成
    const mediaQuery = window.matchMedia('(min-width: 768px)')
    // メディアクエリがtrueかどうかをチェック
    if (mediaQuery.matches) {
      viewItem = 15;
    } else {
      viewItem = 14;
    }
    

    if($contentItem.length > viewItem) {
      $contentItem.each(function(index, el) {
        if(index >= viewItem) {
          $(this).css({'display': 'none'});
        }
      })
    } else {
      $worksMore.css({'display': 'none'});
    }

    $worksMore.click(function() {
      $contentItem.css({'display': 'block'});
      $(this).css({'display': 'none'});
    })
  }
}

//noScroll
class MovieModal {
  constructor(el, opt) {
    this.el = el;
    if ($("." + this.el).length > 0) {
      this.event();
    }
  }
  event() {
    const modalWrap = '.js-new-movie-modal';
    const modalToggle = '.js-new-movie-modal-toggle';
    const modalContent = '.js-new-movie-modal-content';
    const modalClose = '.js-new-movie-modal-close';
    $(modalToggle).on('click',function(){
      const modalTarget = $(this).data('modal');
      $(modalContent).each(function(){
        if($(this).data('modal') == modalTarget){
          $(this).fadeIn();
        }else{
          $(this).fadeOut();
        }
      })
      $(modalWrap).fadeIn();
    })
    $(modalClose).on('click',function(){
      $(modalWrap).fadeOut();
    })

    function initializeModalForMobile() {
      if (window.innerWidth <= 768) {
        $('.js-type-trigger').on('click', function () {
          const targetId = $(this).data('target');
          $('.s-type-wrap').removeClass('is-active');
          $('#' + targetId).addClass('is-active');
        });

        $('.js-modal-close').on('click', function () {
          $(this).closest('.s-type-wrap').removeClass('is-active');
        });
      } else {
        $('.js-type-trigger').off('click');
      }
    }
    initializeModalForMobile();

    $('.js-works-trigger').on('click', function () {
      const targetId = $(this).data('target');
      $('.s-works-wrap').removeClass('is-active');
      $('#' + targetId).addClass('is-active');
    });

    $('.js-modal-close').on('click', function () {
      $(this).closest('.s-works-wrap').removeClass('is-active');
    });
  }
}
//noScroll
class NavToggle {
  constructor(el, opt) {
    this.el = el;
    if ($("." + this.el).length > 0) {
      this.event();
    }
  }
  event() {
    const toggleNav = '.js-nav-target';

    if ($(toggleNav).length) {
      $('.js-nav-toggle').each(function (i) {
        gsap.to(toggleNav, {
          scrollTrigger: {
            trigger: this, // この要素をトリガーとして使用
            start: 'top center',
            end: 'top center',
            onEnter: () => {
              $(toggleNav).find('li').removeClass('is-active');
              $(toggleNav).find(`li:eq(${i})`).addClass('is-active');
            },
            onEnterBack: () => {
              $(toggleNav).find('li').removeClass('is-active');
              $(toggleNav).find(`li:eq(${i})`).addClass('is-active');
            }
          },
        });
      });
    }

  }
}
//noScroll
class PlanTab {
  constructor(el, opt) {
    this.el = el;
    if ($(this.el).length > 0) {
      this.event();
    }
  }
  event() {

    const targetContent = '.js-plan-content';
    $(this.el).on('click',function(){
      $('.js-plan-tab').removeClass('is-active');
      $(this).addClass('is-active');
      const thisIndex = $(this).index();
      $(targetContent).each(function(){
        if($(this).index() == thisIndex){
          $(this).addClass('is-active').fadeIn();
        }else{
          $(this).removeClass('is-active').fadeOut();
        }
      })
      wrapHeightChange();
    })

    function wrapHeightChange() {
      let totalHeight = 0;
      const $planContentWrap = $('.js-plan-content-wrap');

      $planContentWrap.children().each(function() {
        if ($(this).hasClass('is-active')) {
          totalHeight += $(this).outerHeight(true); // marginを含む高さを加算
        }
      });

      $planContentWrap.height(totalHeight);
    }

    wrapHeightChange();
      $(window).resize(wrapHeightChange);
    }
}
//noScroll
class ScrollNavi {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    //画面が読み込まれたら＆リサイズされたら
    $(window).on('load resize', function() {
      var windowWidth = window.innerWidth;
      var elements = $('c-column__left');//position: sticky;を指定している要素
      if (windowWidth >= 768) {/*768px以上にIE用のJSをきかせる*/
        Stickyfill.add(elements);
      }else{
        Stickyfill.remove(elements);
      } 
    });

    // //リンク先のidまでスムーススクロール
    // //※ページ内リンクを行わない場合は不必要なので削除してください
    //     $('#scroll-navi li a').click(function () {
    //   var elmHash = $(this).attr('href');
    //   var pos = $(elmHash).offset().top-0;
    //   $('body,html').animate({scrollTop: pos}, 1000);
    //   return false;
    // });

    console.log('aaa');
  }
}

//noScroll
class SelectToggle {
  constructor(el, opt) {
    this.el = el;
    if ($("." + this.el).length > 0) {
      this.event();
    }
  }
  event() {
    const selectWrap = '.js-contact-select';
    $(selectWrap).each(function(){
      $(this).find('select').on('change',function(){
        let thisVal = $(this).val();
        if(thisVal){
          $(this).parent(selectWrap).addClass('is-active');
        }else{
          $(this).parent(selectWrap).removeClass('is-active');
        }
      })
    })
  }
}
//noScroll
class SmoothScroll {
  constructor(el,opt){
    this.el = el;
    this.event();
  }
  event(){
    // メディアクエリがtrueかどうかをチェック
    var headerHeight = $('.js-header-trigger').outerHeight();     //fixedのヘッダーの高さを取得
    // var urlHash = location.hash;    //URLハッシュを取得
    var animeSpeed = 500;   //スクロールのアニメーションスピード
    // if(urlHash) {
    //   $('body,html').stop().scrollTop(0);
    //   setTimeout(function(){
    //     var target = $(urlHash.split('&')[0]);
    //     var position = target.offset().top - headerHeight;
    //     $('body,html').stop().animate({scrollTop:position}, animeSpeed);
    //   }, 100);
    // }
    $('a[href^="#"]').on({
      'click': function(){
        if($(this).hasClass('js-float')) {
          headerHeight = headerHeight;
        }
        var href= $(this).attr("href");
        var target = $(href);
        var position = target.offset().top - headerHeight;
        $('body,html').stop().animate({scrollTop:position}, animeSpeed);
        headerHeight = $('.js-header-trigger').outerHeight();
      }
    });
  }
}
 
//noScroll
class TopScrollEvent {
  constructor(el,opt){
    this.el = el;
    this.event();
  }
  event(){
  // pin 要素を特定の位置で固定させる
    // gsap.to('.target1', {
    //   y: 0,
    //   scrollTrigger: {
    //     trigger: '.target1',
    //     start: 'top-=250',
    //     end: '+=1800', // 開始位置から500px固定させる
    //     pin: true, // trigger要素を固定
    //     markers: true, // マーカー表示
    //     scrub: true, 
    //   }
    // });
  }
}

// 固定している範囲を取得
// 固定している範囲を6分割


//noScroll
class WebContactBtn {
  constructor(el, opt) {
    this.el = el;
    if ($("." + this.el).length > 0) {
      this.event();
    }
  }
  event() {
    const contactBtn = '.js-web-contact-btn';
    window.addEventListener('scroll', function() {
      if(window.scrollY >= 500) {
        $(contactBtn).fadeIn();
      }else{
        $(contactBtn).fadeOut();
      }
    });
  }
}
//noScroll
class WebModal {
  constructor(el, opt) {
    this.el = el;
    if ($("." + this.el).length > 0) {
      this.event();
    }
  }
  event() {
    const modalWrap = '.js-web-modal';
    const modalToggle = '.js-web-modal-toggle';
    const modalContent = '.js-web-modal-content';
    const modalClose = '.js-web-modal-close';
    $(modalToggle).on('click',function(){
      const modalTarget = $(this).data('modal');
      $(modalContent).each(function(){
        if($(this).data('modal') == modalTarget){
          $(this).fadeIn();
        }else{
          $(this).fadeOut();
        }
      })
      $(modalWrap).fadeIn();
    })
    $(modalClose).on('click',function(){
      $(modalWrap).fadeOut();
    })
  }
}
class Wysiwyg {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $('p').each(function(index, el) {
      if(el.textContent == ' ') {
        el.style.height = '1px';
      }
    })
  }
}


(() => {

})();

//DOMContentLoaded
$(() => {
});

//images resources loaded
$(window).on('load', () => {

  $(window).trigger('loading');
});

//after loading animation
$(window).on('loading', () => {
  let imgParallax = new ImgParallax('js-parallax');
  let inviewEvent = new InviewEvent('.js-inview');
  let top = new Top('p-top');
  let page = new Page('p-page');
  let contact = new Contact('p-contact');
  let openlist = new OpenList('results-text-list');
  let works = new Works('js-works');
  let worksArchive = new WorksArchive('p-worksArchive');
  let archiveWorks = new ArchiveWorks('p-a-works');
  let header = new Header('js-header');
  // let cursor = new Cursor();
  let accordion = new Accordion();
  let moreView = new MoreView("js-moreView");
  let scrollNavi = new ScrollNavi("js-scroll");
  let wysiwyg = new Wysiwyg("c-wysiwyg");
  let sColumn = new SColumn("p-sColumn");
  let smoothScroll = new SmoothScroll();
  let topScrollEvent = new TopScrollEvent("js-scrollEvent");
  let cardEvent = new CardEvent("js-card");
  let navToggle = new NavToggle("js-nav-target");
  let selectToggle = new SelectToggle("js-contact-select");
  let planTab = new PlanTab(".js-plan-tab");
  let modal = new Modal("js-modal");
  // let btnFollowing = new BtnFollowing('c-btn01', 'c-btn01__area');
  let webModal = new WebModal("js-web-modal");
  let movieModal = new MovieModal(["js-new-movie-modal", ".p-new-meo-renew", "js-advertisement-modal"]);
  let webContactBtn = new WebContactBtn("js-web-contact-btn");
  let recruitMake = new RecruitMake("p-recruit-make");
  let mottoo = new Mottoo("p-mottoo");
});

// スマホ・タブレットの向き判定
// $(window).on('orientationchange', ()=>{
//   if(isPortrait()){
//     console.log('isPortrait');
//   } else {
//     console.log('isLandscape');
//   }
// });
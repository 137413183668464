//noScroll
class MovieModal {
  constructor(el, opt) {
    this.el = el;
    if ($("." + this.el).length > 0) {
      this.event();
    }
  }
  event() {
    const modalWrap = '.js-new-movie-modal';
    const modalToggle = '.js-new-movie-modal-toggle';
    const modalContent = '.js-new-movie-modal-content';
    const modalClose = '.js-new-movie-modal-close';
    $(modalToggle).on('click',function(){
      const modalTarget = $(this).data('modal');
      $(modalContent).each(function(){
        if($(this).data('modal') == modalTarget){
          $(this).fadeIn();
        }else{
          $(this).fadeOut();
        }
      })
      $(modalWrap).fadeIn();
    })
    $(modalClose).on('click',function(){
      $(modalWrap).fadeOut();
    })

    function initializeModalForMobile() {
      if (window.innerWidth <= 768) {
        $('.js-type-trigger').on('click', function () {
          const targetId = $(this).data('target');
          $('.s-type-wrap').removeClass('is-active');
          $('#' + targetId).addClass('is-active');
        });

        $('.js-modal-close').on('click', function () {
          $(this).closest('.s-type-wrap').removeClass('is-active');
        });
      } else {
        $('.js-type-trigger').off('click');
      }
    }
    initializeModalForMobile();

    $('.js-works-trigger').on('click', function () {
      const targetId = $(this).data('target');
      $('.s-works-wrap').removeClass('is-active');
      $('#' + targetId).addClass('is-active');
    });

    $('.js-modal-close').on('click', function () {
      $(this).closest('.s-works-wrap').removeClass('is-active');
    });
  }
}
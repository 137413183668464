//noScroll
class ArchiveWorks {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

    let $contentItem = $('.js-content-item');

    if($contentItem.length > 15) {
      $contentItem.each(function(index, el) {
        if(index >= 15) {
          $(this).css({'display': 'none'});
        }
      })
    }
  }
}

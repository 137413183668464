//noScroll
class Header {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $(".js-drawer").click(function() {
       $("." + this.el).toggleClass('is-active');
       $(".js-sp-menu").toggleClass('is-active');
       $('html').toggleClass('is-hidden is-drawer-active');
    })

    $(".js-aco").click(function() {
      $(this).toggleClass('is-active').next().slideToggle();
      $(this).find('.js-aco-trigger').toggleClass('is-active');
    })

    let options = {
      threshold: [0]
    }
    const observerEnter = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio >= 0.9) {
          $("." + this.el).removeClass('is-scroll');
        } else {
          $("." + this.el).addClass('is-scroll');
        }
      });
    }, options);
    let el = document.querySelector('.js-header-bgTrigger')
    observerEnter.observe(el);
  }
}

class WorksArchive {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    let page = 2;  // 最初は2ページ目からスタート
    let loading = false; // ローディングフラグ
    let noMorePosts = false; // 投稿がなくなったフラグ
    
    // スクロールイベントを監視
    $(window).on('scroll', function () {
      if (loading || noMorePosts) return;
    
      // ページ下部に近づいたら次を読み込む
      if ($(window).scrollTop() + $(window).height() > $(document).height() - 400) {
        loading = true; // ローディング中フラグを立てる
    
        $.ajax({
          url: ajaxurl, // WordPress が提供する変数
          type: 'POST',
          data: {
            action: 'load_more_posts',
            page: page,  // ページ番号を送信
            post_type: 'works',  // 投稿タイプ
            works_cat: params.works_cat,  // 必要に応じてカテゴリー情報も送信
          },
          success: function (response) {
            if (response === 'no_more_posts') {
              noMorePosts = true;  // 投稿がなくなった
            } else {
              $('#content').append(response);  // 投稿を追加
              page++;  // ページ番号をインクリメント
            }
          },
          complete: function () {
            loading = false;  // ローディングフラグを解除
          },
        });
      }
    });
  }
}

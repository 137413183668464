//noScroll
class WebModal {
  constructor(el, opt) {
    this.el = el;
    if ($("." + this.el).length > 0) {
      this.event();
    }
  }
  event() {
    const modalWrap = '.js-web-modal';
    const modalToggle = '.js-web-modal-toggle';
    const modalContent = '.js-web-modal-content';
    const modalClose = '.js-web-modal-close';
    $(modalToggle).on('click',function(){
      const modalTarget = $(this).data('modal');
      $(modalContent).each(function(){
        if($(this).data('modal') == modalTarget){
          $(this).fadeIn();
        }else{
          $(this).fadeOut();
        }
      })
      $(modalWrap).fadeIn();
    })
    $(modalClose).on('click',function(){
      $(modalWrap).fadeOut();
    })
  }
}
//noScroll
class Works {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){


    // 制作実績詳細のスクロールアクション

    $(function () {
      // スクロールを開始したら
      $(window).on("scroll", function () {



        // ファーストビューの高さを取得
        let mvHeight = $(".p-s-works__mv").height();
        let footerHight = $(".l-footer-contact").offset().top - 1000;
        if (window.matchMedia('(min-width: 960px)').matches) {
          if ($(window).scrollTop() > 10) {
            // スクロールの位置がファーストビューより下の場合にclassを付与
            $(".js-works-fixed").addClass("js-styleChange");
          } else {
            // スクロールの位置がファーストビューより上の場合にclassを外す
            $(".js-works-fixed").removeClass("js-styleChange");
          }
        }
        if (window.matchMedia('(max-width: 959px)').matches) {
          if ($(window).scrollTop() > 50) {
            $(".p-s-works__siteLink").addClass("js-isShow");
          }else{
            $(".p-s-works__siteLink").removeClass("js-isShow")
          }
        }


          if($(window).scrollTop() > footerHight){
            $(".js-works-fixed").addClass('js-hide');
            $(".p-s-works__siteLink").removeClass("js-isShow")
          }else{
            $(".js-works-fixed").removeClass('js-hide');
            $(".p-s-works__siteLink").addClass("js-isShow");
          }







      });
    });

  }
}

class Wysiwyg {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $('p').each(function(index, el) {
      if(el.textContent == ' ') {
        el.style.height = '1px';
      }
    })
  }
}
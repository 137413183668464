//noScroll
class TopScrollEvent {
  constructor(el,opt){
    this.el = el;
    this.event();
  }
  event(){
  // pin 要素を特定の位置で固定させる
    // gsap.to('.target1', {
    //   y: 0,
    //   scrollTrigger: {
    //     trigger: '.target1',
    //     start: 'top-=250',
    //     end: '+=1800', // 開始位置から500px固定させる
    //     pin: true, // trigger要素を固定
    //     markers: true, // マーカー表示
    //     scrub: true, 
    //   }
    // });
  }
}

// 固定している範囲を取得
// 固定している範囲を6分割


//noScroll
class Accordion {
  constructor(el,opt){
    $(".js-trigger").on('click', function() {
      $(this).toggleClass('is-active').next().slideToggle();
    })

    $(".js-trigger-child").on('click', function() {
      $(this).toggleClass('is-active').find('.js-trigger-child__target').slideToggle();
    })

    if (window.matchMedia('(max-width: 959px)').matches) {
      $(".js-trigger_sp").on('click', function() {
        $(this).toggleClass('is-active').next().slideToggle();
      })
    }
  }
}

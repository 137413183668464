//noScroll
class WebContactBtn {
  constructor(el, opt) {
    this.el = el;
    if ($("." + this.el).length > 0) {
      this.event();
    }
  }
  event() {
    const contactBtn = '.js-web-contact-btn';
    window.addEventListener('scroll', function() {
      if(window.scrollY >= 500) {
        $(contactBtn).fadeIn();
      }else{
        $(contactBtn).fadeOut();
      }
    });
  }
}
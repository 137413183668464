//noScroll
class ImgParallax {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    // this.para_ele = document.querySelectorAll("." + this.el);
    // this.para_ele.forEach((ele, index)=> {
    //   if(ele.classList.contains('js-parallax--reverse')) {
    //     this.translateY = '20px';
    //   } else {
    //     this.translateY = '-20px';
    //   }
    //   ele.classList.add(this.el + index);
    //   gsap.to('.' + this.el + index + ' img', {
    //     translateY: this.translateY,
    //     scrollTrigger: {
    //       trigger: '.' + this.el + index, // 要素がビューポートに入ったときにアニメーション開始
    //       start: 'top bottom',
    //       scrub: 3,
    //     }
    //   })
    // })


    $(function(){
      if (window.matchMedia('(min-width:960px)').matches) {
        /* ウィンドウサイズ669以上、767以下の処理を記述 */
        gsap.utils.toArray('.js-parallax-fideDown').forEach(wrap => {
          const y = wrap.getAttribute('data-y') || +100;

          gsap.to(wrap, {
            y: y,
            scrollTrigger: {
              trigger: wrap,
              start: 'top bottom',
              end: 'bottom top',
              scrub: 1,

            }
          })
        });
    }})

  }
}

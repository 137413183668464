//noScroll
class Top {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // mv spスクロール
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー






    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // mv スクロールイベント
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    // スクロールを通常に戻すためにコメントアウト　LCW小宮山
    // const mvImageChange = () => {
    //   const mvChangePoint01 = document.querySelector(".js-mvChangePoint01").offsetTop;
    //   const mvChangePoint02 = document.querySelector(".js-mvChangePoint02").offsetTop;
    //   const mvImages = document.querySelectorAll(".js-vision-img")

    //   window.addEventListener('scroll', (event) => {
    //     let scroll_y = window.scrollY;
    //     const mvImage01 = document.querySelector(".js-vision-img01")
    //     const mvImage02 = document.querySelector(".js-vision-img02")
    //     const mvImage03 = document.querySelector(".js-vision-img03")

    //     if( scroll_y < mvChangePoint01){

    //       mvImage01.style.opacity = 1
    //       mvImage02.style.opacity = 0
    //       mvImage03.style.opacity = 0

    //     }else if(mvChangePoint01 < scroll_y && scroll_y < mvChangePoint02){
    //       mvImage01.style.opacity = 0
    //       mvImage02.style.opacity = 1
    //       mvImage03.style.opacity = 0

    //     }else{
    //       mvImage01.style.opacity = 0
    //       mvImage02.style.opacity = 0
    //       mvImage03.style.opacity = 1

    //     }
		// 	});
    // }
    // $(function(){
    //   if (window.matchMedia('(min-width: 960px)').matches) {
    //     /* ウィンドウサイズ669以上、767以下の処理を記述 */
    //     mvImageChange();
    // }})

    // 通常のスライド実装
    new Swiper(".js-vision-swiper", {
      speed: 1000, // ループの時間
      loop: true, // ループ有効
      slidesPerView: 1,
      effect: 'fade',
      autoplay: {
        delay: 4000, // 途切れなくループ
      },
    });

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // ニュース部分 swiper
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    new Swiper(".headingNews-swiper", {
      speed: 1000, // ループの時間
      slidesPerView: 1,
      allowTouchMove: false,
      autoplay: {
        delay: 6000, // 途切れなくループ
      },

      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    });

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // 〆ニュース部分 swiper
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // Works部分 swiper
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー


    const worksPageDisplay = () => {

      const numItems = document.querySelectorAll(".js-works-num")
      numItems.forEach((item, index) => {
        if (window.matchMedia('(min-width: 960px)').matches) {
          item.innerHTML = `00${index + 1}`
        }
        if (window.matchMedia('(max-width: 959px)').matches) {
        item.innerHTML = `00${index + 2}`
        }
      })
    }


    worksPageDisplay()

    new Swiper(".works-swiper", {
      slidesPerView: 1.5,
      spaceBetween: 10,
      loop: true, // ループ有効
      // speed: 2000, // ループの時間
      // autoplay: {
        //   delay: 4000, // 途切れなくループ
        // },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "fraction",
        },
        breakpoints: {
          960: {
          spaceBetween: 80,
          slidesPerView: 3.5,
        },
      },
    });


    // new Swiper(".works-swiper", {
    //   slidesPerView: 4.1,
    //   spaceBetween: 80,
    //   loop: true, // ループ有効
    //   speed: 2000, // ループの時間
    //   // autoplay: {
    //   //   delay: 6000, // 途切れなくループ
    //   // },
    // });





    // let swiper, swiperbool;
    // const breakPoint = 959;

    // if (breakPoint > window.innerWidth) {
    //   swiperbool = false;
    // } else {
    //   createSwiper();
    //   swiperbool = true;
    // }

    // window.addEventListener('resize', function() {
    //   if (breakPoint > window.innerWidth && swiperbool) {
    //     swiper.destroy(false, true);
    //     swiperbool = false;
    //   } else if (breakPoint <= window.innerWidth && !(swiperbool)) {
    //     createSwiper();
    //     swiperbool = true;
    //   }
    // });

    // function createSwiper() {
    //   swiper = new Swiper(".works-swiper", {
    //     breakpoints: {
    //       960: {
    //         slidesPerView: 4.1,
    //         spaceBetween: 40,
    //       }
    //     },
    //   });
    // }

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // 〆Works部分 swiper
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // Works部分 動き
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    gsap.to('.s-works', { // 動かしたい要素
      opacity: "1",
      scrollTrigger: {
        trigger: '.s-mv', // 要素がビューポートに入ったときにアニメーション開始
        start: 'top+=200 top', // アニメーション開始位置
        end: 'bottom top', // アニメーション終了位置
        scrub: 1,
      }
    });

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // 〆Works部分 動き
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // What we do.部分 動き
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    gsap.to('.s-were__imgWrap', { // 動かしたい要素
      top: '60rem',
      scrollTrigger: {
        trigger: '.s-were__wrap', // 要素がビューポートに入ったときにアニメーション開始
        start: 'top bottom', // アニメーション開始位置
        end: 'bottom top', // アニメーション終了位置
        scrub: 10,
      }
    });

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // 〆What we do.部分 動き
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // コラム swiper
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    // option は使い回すので別に書く
    const options01 = {
      slidesPerView: 3,
      spaceBetween: 20,
      loop: true,

      navigation: {
        nextEl: '.js-column-swiper01-next',
        prevEl: '.js-column-swiper01-prev'
      }
    }
    const breakPoint = 850;
    let swiper01;
    if ( window.innerWidth > breakPoint ) {
      swiper01 = new Swiper('.js-column-swiper01', options01);
    } else {
      swiper01 = undefined;
    }
    window.addEventListener('resize', () => {
      if ( window.innerWidth > breakPoint ) {
        if( swiper01 ) return;
        swiper01 = new Swiper('.js-column-swiper01', options);
      } else {
        if( !swiper01 ) return;
        swiper01.destroy();
        swiper01 = undefined;
      }
    }, false);


    // option は使い回すので別に書く
    const options = {
      slidesPerView: 3,
      spaceBetween: 20,
      loop: true,

      navigation: {
        nextEl: '.js-column-swiper02-next',
        prevEl: '.js-column-swiper02-prev'
      }
    }
    let swiper02;
    if ( window.innerWidth > breakPoint ) {
      swiper02 = new Swiper('.js-column-swiper02', options);
    } else {
      swiper02 = undefined;
    }
    window.addEventListener('resize', () => {
      if ( window.innerWidth > breakPoint ) {
        if( swiper02 ) return;
        swiper02 = new Swiper('.js-column-swiper02', options);
      } else {
        if( !swiper02 ) return;
        swiper02.destroy();
        swiper02 = undefined;
      }
    }, false);

    $('.js-plan-content:not(.is-active)').css({'display': 'none'});

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // 〆コラム swiper
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // ローディング
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

    if($(".js-loading").length) {
      var tl = gsap.timeline();

      tl.to(".s-mv__ttl--n", 1, {
        delay: 1,
        opacity: 1,
        scale: 1,
        ease: CustomEase.create("custom", "M0,0,C0,0,0.128,-0.087,0.178,0,0.222,0.078,0.332,0.535,0.364,0.596,0.4,0.664,0.499,0.793,0.542,0.826,0.6,0.87,0.696,0.936,0.798,0.968,0.912,1.004,1,1,1,1"),

        onStart: function() {
          $('html').toggleClass('is-hidden');
        }
      });

      tl.to(".s-mv__ttl--n path:first-of-type", 1, {
        x: '-5vw',
        delay: 1,
        opacity: 0,
        ease: CustomEase.create("custom", "M0,0,C0,0,0.144,-0.051,0.194,0.036,0.238,0.114,0.332,0.535,0.364,0.596,0.4,0.664,0.499,0.793,0.542,0.826,0.6,0.87,0.696,0.936,0.798,0.968,0.912,1.004,1,1,1,1"),
        onStart: function() {
          $('html').toggleClass('is-hidden');
        }
      });

      tl.to(".s-mv__ttl--n path:last-of-type", 1, {
        x: '5vw',
        opacity: 0,
        ease: CustomEase.create("custom", "M0,0,C0,0,0.144,-0.051,0.194,0.036,0.238,0.114,0.332,0.535,0.364,0.596,0.4,0.664,0.499,0.793,0.542,0.826,0.6,0.87,0.696,0.936,0.798,0.968,0.912,1.004,1,1,1,1"),
        onStart: function() {
          $('html').toggleClass('is-hidden');
        }
      }, "<");

      tl.to(".s-mv__ttl svg", .5, {
        opacity: 1,
      }, "-=0.5");

      tl.to(".re", .8, {
        translateX: 0,
        translateY: 0,
        ease: CustomEase.create("custom", "M0,0,C0,0,0.144,-0.051,0.194,0.036,0.238,0.114,0.332,0.535,0.364,0.596,0.4,0.664,0.499,0.793,0.542,0.826,0.6,0.87,0.696,0.936,0.798,0.968,0.912,1.004,1,1,1,1"),
        delay: .3,
      });

      tl.to(".vision", .8, {
        translateX: 0,
        translateY: 0,
        ease: CustomEase.create("custom", "M0,0,C0,0,0.144,-0.051,0.194,0.036,0.238,0.114,0.332,0.535,0.364,0.596,0.4,0.664,0.499,0.793,0.542,0.826,0.6,0.87,0.696,0.936,0.798,0.968,0.912,1.004,1,1,1,1"),
      }, "<");

      tl.to(".s-mv__ttl svg > *:not(g)", .5, {
        y: 0,
        opacity: 1,
        stagger: 0.05,  //0.5秒遅れてそれぞれ再生
      }, "-=.3");

      tl.to(".s-mv__ttl p", .5, {
        y: 0,
        opacity: 1,
      }, "-=1");

      tl.to(".s-mv__el", 1.5, {
        opacity: 1,
        stagger: 0.08,  //0.5秒遅れてそれぞれ再生
      }, "-=1");

      tl.to(".s-mv-news,.s-mv__scroll", .5, {
        opacity: 1,
      }, "-=1");

      if(window.matchMedia('(min-width: 980px)').matches) {
        tl.to(".js-header", .5, {
          top: '6rem',
          onComplete: function() {
            $('html').toggleClass('is-hidden');
          }
        }, "<");
      } else {
        tl.to(".js-header", .5, {
          translateY: 0,
          onComplete: function() {
            $('html').toggleClass('is-hidden');
          }
        }, "<");
      }
    }

    cookie.set('loading', 'true', '', '', '1');

    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
    // ローディング
    // ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  }
}

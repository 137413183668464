//noScroll
class SColumn {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    new Swiper(".sClumn-Swiper", {
      slidesPerView: 1.7,
      spaceBetween: 20,
      // loop: true, // ループ有効
      speed: 500, // ループの時間
      autoplay: {
        delay: 4000,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        type: "fraction",
      },
      navigation: {
        nextEl: ".sClumn-Swiper-button-next",
        prevEl: ".sClumn-Swiper-button-prev",
      },
      breakpoints: {
        960: {
          centeredSlides: false,
          spaceBetween: 30,
          slidesPerView: 3,
        },
      },
    });
  }
}

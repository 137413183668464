class Mottoo {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $(window).on("load resize scroll", function () {
      var mvHeight = $(".js-mv").outerHeight(); // .js-mvの高さを取得
      if ($(window).scrollTop() > mvHeight) {
        $("html").addClass("is-scroll");
      } else {
        $("html").removeClass("is-scroll");
      }
    });

    $(window).on('scroll', function() {
      var footerTop = $('.js-footer').offset().top;
      var windowBottom = $(window).scrollTop() + $(window).height();
      var mvFixedHeight = $('.js-mv-fixed').outerHeight();
    
      if (windowBottom >= footerTop) {
        $('.js-mv-fixed').fadeOut();
      } else {
        $('.js-mv-fixed').fadeIn();
      }
    });

    let swiperInstance;

    function initializeSwiper() {
      if (window.innerWidth <= 959) {
        swiperInstance = new Swiper('.c-swiper-column', {
          slidesPerView: 2.2,
          spaceBetween: 12,
        });

        const swiperPriceElements = document.querySelectorAll('.c-swiper-price');
        swiperPriceElements.forEach((element) => {
          new Swiper(element, {
            slidesPerView: 1.4,
            spaceBetween: 12,
          });
        });
        // if (!swiperInstance) {

        // }
      } else {
        if (swiperInstance) {
          swiperInstance.destroy(true, true);
          swiperInstance = null;
        }
      }
    }

    $(document).ready(initializeSwiper);
    $(window).resize(initializeSwiper);

    $('.js-close').on('click', function() {
      $('.js-mv-fixed').css('display', 'none');
    });

    $('.js-q').click(function() {
      $(this).find('.plus').toggleClass('is-active');
      $(this).next().slideToggle(200);
    })

    // $('.js-acc').click(function() {
    //   $(this).find('.plus').toggleClass('is-active');
    //   $(this).next().slideToggle();
    // })

    $('.js-drawer').on('click', function() {
      $('html').toggleClass('is-open');
      $("html").toggleClass("is-scroll");
    });

    $('.js-nav-content a, .js-overlay').on('click', function() {
      $('html').removeClass('is-open');
    });

    $('.js-more-btn').click(function () {
      if (window.matchMedia('(max-width: 959px)').matches) {
        $('.js-faq-content').css('height', 'auto');
        $('.js-more-bg, .js-more-btn').css('display', 'none');
      }
    });


    $('.js-tab-area li').eq(1).addClass('is-active');
    $('.js-tab-content').eq(1).removeClass('no-active');
    $('.js-tab-area li').on('click', function() {
      const index = $(this).index();
      $('.js-tab-area li').removeClass('is-active');
      $(this).addClass('is-active');
      $('.js-tab-content').addClass('no-active');
      $('.js-tab-content').eq(index).removeClass('no-active');
    });


    var headerHeight = $('.js-head-mottoo').outerHeight(); // fixedのヘッダーの高さを取得
    var urlHash = location.hash; // URLハッシュを取得
    var animeSpeed = 500; // スクロールのアニメーションスピード
    
    if (urlHash) {
      $('body,html').scrollTop(0);
      setTimeout(function () {
        var target = $(urlHash);
        var position = target.offset().top - headerHeight;
        $('body,html').animate({ scrollTop: position }, animeSpeed);
      }, 500);
    }

    // アンカーリンクのクリックイベント処理
    $('a[href^="#"]').click(function () {
      const href = $(this).attr("href");
      const target = $(href);
      const position = target.offset().top - headerHeight;
      $('body,html').stop().animate({ scrollTop: position }, 500);
      return false;
    });

    gsap.to(
      ".js-icon-pop",
      {
        opacity: 1,
        y: 0,
        stagger: 0.3,
        duration: 0.2,
        scrollTrigger: {
          trigger: ".js-trigger-icon",
          start: "top bottom-=30%",
        }
      }
    );
    gsap.timeline()
      .to('.js-mv-copy', { clipPath: "inset(0 0% 0 0)", duration: 0.4 })
      .to('.js-mv-img', { y: '0%', opacity: 1, duration: 0.4 }, '<')
      .to(['.js-head-mottoo', '.js-drawer'], { y: '0%', opacity: 1, duration: 0.3 }, '-=0.1')
      .to('.js-marquee', { opacity: 1, duration: 0.4 },  '<')
      .to('.js-mv-fixed', { y: '0%', opacity: 1, duration: 0.4 },  '<');

    ScrollTrigger.matchMedia({
      "(max-width: 768px)": function() {
        ScrollTrigger.create({
          trigger: '.js-trigger-area01',
          start: 'top center',
          toggleClass: { targets: '.js-yellow-line', className: 'is-active' },
          once: true,
          duration: 0.2,
          // markers: true,
        });
        gsap.to(
          ".js-trigger-area li",
          {
            opacity: 1,
            y: 0,
            stagger: 0.3,
            duration: 0.3,
            scrollTrigger: {
              trigger: ".js-trigger-area",
              start: "top bottom-=15%",
              // markers: true,
            }
          }
        );
      },
      "(min-width: 769px)": function() {
        ScrollTrigger.create({
          trigger: '.js-trigger-area01',
          start: 'center-=20% center',
          toggleClass: { targets: '.js-yellow-line', className: 'is-active' },
          once: true,
        });
        gsap.to(
          ".js-trigger-area li",
          {
            opacity: 1,
            y: 0,
            stagger: 0.3,
            duration: 0.3,
            scrollTrigger: {
              trigger: ".js-trigger-area",
              start: "top bottom-=25%",
            }
          }
        );
      }
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.js-trigger-area02',
        start: 'top+=20% center',
        once: true,
        // markers: true,
      }
    });
    
    tl.to('.js-before-content', {
      y: '0%',
      opacity: 1,
      duration: 0.5,
      ease: 'power2.out'
    })
    .add(() => {
      if (window.innerWidth > 768) {
        gsap.to('.js-arrow-tb', {
          clipPath: 'inset(0 0 0 0)',
          duration: 0.4,
          ease: 'power2.out'
        });
        gsap.to('.js-logo-mottoo', {
          opacity: 1,
          duration: 0.4,
          ease: 'power2.out'
        });
      } else {
        gsap.to('.js-arrow-tb', {
          clipPath: 'inset(0 0 0 0)',
          duration: 0.4,
          ease: 'power2.out'
        });
        gsap.to('.js-logo-mottoo-sp', {
          opacity: 1,
          duration: 0.4,
          ease: 'power2.out'
        });
      }
    })
    .to('.js-after-content', {
      y: '0%',
      opacity: 1,
      duration: 0.5,
      ease: 'power2.out'
    },  '+=0.5');
    
    const tl02 = gsap.timeline({
      scrollTrigger: {
        trigger: '.js-trigger-area03',
        start: 'center-=20% center',
      },
    });

    tl02.to('.js-arrow-lr', {
      clipPath: 'inset(0 0 0 0)',
      duration: 0.3,
      ease: 'power2.inOut',
    });
    
    tl02.to(
      '.js-arrow-rl',
      {
        clipPath: 'inset(0 0 0 0)',
        duration: 0.3,
        ease: 'power2.inOut',
      },
      '+=0.1'
    );
  }
}

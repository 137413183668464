//noScroll
'use strict'

// Basic Exponential Tween For Vector1
class Tween {
  constructor(pos, alphaSpeed) {
    this.position = pos
    this.velocity = pos
    this.alphaSpeed = alphaSpeed
  }

  lerp(value1, value2, alpha) {
    return value1 + (value2 - value1) * alpha
  }

  step(target) {
    const alpha = Math.exp(-this.alphaSpeed * (1.0 / 60.0))
    const newPosition = this.lerp(target, this.position, alpha)

    this.velocity = newPosition - this.position
    this.position = newPosition
  }

  reset() {
    this.position = 0
    this.velocity = 0
  }
}

// Basic Exponential Tween For Vector2
class Tween2 {
  constructor(pos, alphaSpeed) {
    this.position = { x: pos.x, y: pos.y }
    this.velocity = { x: 0, y: 0 }
    this.alphaSpeed = alphaSpeed
    this.direction = 0
  }

  lerp(value1, value2, alpha) {
    return value1 + (value2 - value1) * alpha
  }

  step(target) {
    const alpha = Math.exp(-this.alphaSpeed * (1.0 / 60.0))
    const newPositionX = this.lerp(target.x, this.position.x, alpha)
    const newPositionY = this.lerp(target.y, this.position.y, alpha)

    this.velocity.x = newPositionX - this.position.x
    this.velocity.y = newPositionY - this.position.y
    this.position.x = newPositionX
    this.position.y = newPositionY
  }

  calcDirection(target) {
    this.direction = Math.atan2(this.position.y - target.y, this.position.x - target.x)
  }

  reset() {
    this.position.x = 0
    this.position.y = 0
    this.velocity.x = 0
    this.velocity.y = 0
    this.direction = 0
  }

  get x() {
    return this.position.x
  }

  get y() {
    return this.position.y
  }
}

class BtnFollowing {
  constructor(el,opt){
    this.el = el;
    this.opt = opt;
    this.$body = document.querySelector("." + this.el)
    this.$area = document.querySelector("." + this.opt)

    this.x = 0
    this.y = 0
    this.k = 0.5
    this.isSticked = false
    this.tween = new Tween2({ x: 0, y: 0 }, 6)

    this.start()
  }

  start() {
    this.$body.addEventListener('mouseenter', () => {
      this.isSticked = true
      this.update()
    })

    this.$body.addEventListener('mouseleave', () => {
      this.isSticked = false
    })
  }

  kill() {}

  update() {
    let cx = 0
    let cy = 0
    let tx = 0
    let ty = 0

    if (this.isSticked) {
      const domRect = this.$area.getBoundingClientRect()
      cx = domRect.left + domRect.width / 2
      cy = domRect.top + domRect.height / 2
      tx = (Mouse.x - cx) * (1.0 - this.k)
      ty = (Mouse.y - cy) * (1.0 - this.k)
    }

    this.tween.step({ x: tx, y: ty })
    this.x = this.tween.position.x
    this.y = this.tween.position.y

    this.$body.style.transform = `translate3d(${this.x}px, ${this.y}px, 0)`
  }
}

class RecruitMake {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $(window).on("load resize scroll", function () {
      var mvHeight = $(".js-mv").outerHeight(); // .js-mvの高さを取得
      if ($(window).scrollTop() > mvHeight) {
        $("html").addClass("is-scroll");
      } else {
        $("html").removeClass("is-scroll");
      }
    });

    new Swiper(".mock-swiper", {
      loop: true, // ループ有効
      effect: "creative",
      speed: 600,
      creativeEffect: {
        prev: {
          shadow: true,
          translate: ["-20%", 0, -1],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      },
    });

    let swiperInstance;

    function initializeSwiper() {
      if (window.innerWidth <= 959) {
        if (!swiperInstance) {
          swiperInstance = new Swiper('.c-swiper-column', {
            slidesPerView: 2.2,
            spaceBetween: 12,
          });
        }
      } else {
        if (swiperInstance) {
          swiperInstance.destroy(true, true);
          swiperInstance = null;
        }
      }
    }

    $(document).ready(initializeSwiper);
    $(window).resize(initializeSwiper);

    gsap.set(".js-mv-phone", { opacity: 0, y: 100 });
    gsap.set(".js-mv-copy", { clipPath: "inset(0 100% 0 0)" });
    gsap.set(".js-mv-award", { clipPath: "inset(0 100% 0 0)" });
    gsap.set(".js-mv-auto", { opacity: 0, y: 100 });
    gsap.set(".js-header-recruit", { opacity: 0, y: -50 });

    if (window.innerWidth <= 959) {
      gsap.set(".js-mv-fixed", { opacity: 0, y: 100 });
    } else {
      gsap.set(".js-mv-fixed", { opacity: 0, x: 100 });
    }

    // アニメーション
    gsap.timeline()
      .to(".js-mv-phone", {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "power3.out"
      })
      .to(".js-mv-copy", {
        clipPath: "inset(0 0% 0 0)",
        duration: 0.5,
        ease: "power3.out"
      }, "<")
      .to(".js-mv-award", {
        clipPath: "inset(0 0% 0 0)",
        duration: 0.5,
        ease: "power3.out"
      }, "<")
      .to(".js-mv-auto", {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "power3.out"
      })
      .to(".js-header-recruit", {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "power3.out"
      }, "<")
      .to(".js-mv-fixed", {
        opacity: 1,
        x: window.innerWidth > 959 ? 0 : undefined, // PCの場合はx方向にアニメーション
        y: window.innerWidth <= 959 ? 0 : undefined, // スマホの場合はy方向にアニメーション
        duration: 0.3,
        ease: "power3.out"
      }, "<");

    $('.js-close').on('click', function() {
      $('.js-mv-fixed').css('display', 'none');
    });

    $('.js-q').click(function() {
      $(this).find('.plus').toggleClass('is-active');
      $(this).next().slideToggle();
    })

    $('.js-acc').click(function() {
      $(this).find('.plus').toggleClass('is-active');
      $(this).next().slideToggle();
    })

    $('.js-drawer').on('click', function() {
      $('html').toggleClass('is-open');
      $("html").toggleClass("is-scroll");
    });

    $('.js-nav-content a').on('click', function() {
      $('html').removeClass('is-open');
    });

    $('.js-more-btn').click(function () {
      if (window.matchMedia('(max-width: 959px)').matches) {
        $('.js-faq-content').css('height', 'auto');
        $('.js-more-bg, .js-more-btn').css('display', 'none');
      }
    });

    gsap.set(".js-anime-bg", { clipPath: "inset(0 0 0 100%)" });

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.target.classList.contains('is-inview')) {
          gsap.to(".js-anime-bg", {
            clipPath: "inset(0 0 0 0%)",
            duration: 0.3,
            ease: "power2.inOut"
          });
        }
      });
    });
    
    observer.observe(document.querySelector(".js-contact-area"), { attributes: true, attributeFilter: ["class"] });
    
    

    var headerHeight = $('.js-header-recruit').outerHeight(); // fixedのヘッダーの高さを取得
    var urlHash = location.hash; // URLハッシュを取得
    var animeSpeed = 500; // スクロールのアニメーションスピード
    
    if (urlHash) {
      $('body,html').scrollTop(0);
      setTimeout(function () {
        var target = $(urlHash);
        var position = target.offset().top - headerHeight;
        $('body,html').animate({ scrollTop: position }, animeSpeed);
      }, 500);
    }

    // アンカーリンクのクリックイベント処理
    $('a[href^="#"]').click(function () {
      const href = $(this).attr("href");
      const target = $(href);
      const position = target.offset().top - headerHeight;
      $('body,html').stop().animate({ scrollTop: position }, 500);
      return false;
    });
  }
}
